
export const states = [
    {
        label : 'Province No. 1 ',
        value : 'Province No. 1 '
    },
    {
        label : 'Province No. 2',
        value : 'Province No. 2'
    },
    {
        label : 'Bagmati',
        value : 'Bagmati'
    },
    {
        label : 'Gandaki',
        value : 'Gandaki'
    },
    {
        label : 'Lumbini',
        value : 'Lumbini'
    },
    {
        label : 'Karnali',
        value : 'Karnali'
    },
    {
        label : 'Sudurpashchim',
        value : 'Sudurpashchim'
    },
]