import * as Yup from 'yup'
import { phoneRegression } from '../../../../utils/phoneRegression'

export interface verification_information {
  type: string,
  issued_date: string,
  issued_authority: string,
  images: string,
}

export interface ICreateAccount {
  firstName: string
  lastName: string
  phoneNumber: string
  emailAddress: string
  password: string
  confirmPassword: string
  companyName: any
  companyType: string
  directorFirstName: string
  directorLastName: string
  address: string
  city: string
  activity: any
  zipCode: any
  country: string
  currency: string
  website: string,
  social: {
    facebook: string,
    instagram: string,
    twitter: string
  },
  companyImage: any,
  officeHours: any,
  companyNumber: string,
  state: string,
  // issued_authority:string,
  // issued_date: any,
  // number:string,
  // type:string
  documents: any
}

var regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

//eslint-disable-next-line
const URLRegx = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

const createAccountSchemas = [
  Yup.object({
    companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required("Company Name is required"),
    companyType: Yup.string().required("Company Type is required").label('CompanyType'),
    emailAddress: Yup.string().email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required').label('Email Address'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .matches(regx, "Password must be at least 8 characters long including at least one UPPERCASE, one lowercase, one Number and one Special Character")
      .label('Password'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required('Confirm Password is required')
      .label('confirm Password'),
  }),
  Yup.object({
    companyNumber: Yup.string()
      .min(5, "Company Number is not valid")
      .max(15, "Enter valid company number")
      .required("Company Number is required"),
    officeHoursFrom: Yup.string().required('Field is required'),
    officeHoursTo: Yup.string().required('Field is required'),
    website: Yup.string()
    .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
    .nullable().label('Website').url('Enter a valid url eg: https://www.example.com'),
    companyImage : Yup.mixed()
    .required('Company Image is required')
    .label('Company Image')
  }),
  Yup.object({
    directorFirstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required("Director First Name is required")
    .label('FirstName'),
  directorLastName: Yup.string().required("Director Last Name is required")
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .label('LastName'),
    firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required')
    .label('First Name'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required')
    .label('Last Name'),
    phoneNumber: Yup.string()
    .min(10, "Invalide Phone Number")
    .max(10, "Enter only 10 digit number")
    .matches(phoneRegression, 'Phone number is not valid')
    .required("Phone number is required")
    .label('Phone Number'),
    address: Yup.string().required("Address is required")
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .label('Address'),
  city: Yup.string().required("City is required")
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .label('City'),
  zipCode: Yup.string().min(5, "Minimum 5 characters").max(5, 'Maximum 5 characters').nullable().label('Zip Code'),
  state: Yup.string().required("State is required").label('State'),
    social: Yup.object().shape({
      facebook: Yup.string()
        .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
        .nullable().url('Enter a valid url eg: https://www.example.com').label('Facebook'),
      twitter: Yup.string()
        .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
        .nullable().url('Enter a valid url eg: https://www.example.com').label('Twitter'),
      instagram: Yup.string()
        .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
        .nullable().url('Enter a valid url eg: https://www.example.com').label('Instagram'),
    })
  }),
  Yup.object({
    // type: Yup.string().required('Verification Type is required').label('Type'),
    // number: Yup.string()
    // .label('Verification Number').nullable().max(20 ,'Maximum 20 numbers' ),
    // issued_date : Yup.date().notRequired().label('Issued Date'),
    // issued_authority : Yup.string().nullable().label('Issued Date'),
    verificationDocuments: Yup.array().min(1).of(Yup.mixed().required('Image is required'))
  }),
]

const inits = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  companyType: '',
  activity: [],
  directorFirstName: '',
  directorLastName: '',
  address: '',
  city: '',
  zipCode: '',
  country: 'nepal',
  currency: 'npr',
  website: '',
  // officeHours:['' , ''],
  officeHoursFrom: '',
  officeHoursTo: '',
  companyNumber: '',
  state: '',
  social: {
    facebook: '',
    instagram: '',
    twitter: ''
  },
  companyImage: '',
  verificationDocuments: [],
  // issued_authority:'',
  // issued_date: '',
  // number:'',
  // type:''
}

export { createAccountSchemas, inits }