import axios from '../../../setup/axios/SetupAxios'

export const getCompany = async () => {
  const { data } = await axios({
    method: 'GET',
    url: `/supplier/company`,
  })
  return data
}


export const editCompany = async (values: any) => {
  const response = await axios.put(`/supplier/company`, {
    name: values.companyName,
    email: values.email,
    type: values.companyType,
    website: values.website,
    activities: values.activity,
    managing_director: `${values.directorFirstName} ${values.directorLastName}`,
    country: "Nepal",
    address: values.address,
    city: values.city,
    state: values.state,
    currency: values.currency,
    postal_code: values.zipCode,
    contact_person: values.contactPerson,
    company_phone: values.phoneNumber,
    contact_person_phone: values.contactPersonPhone,
    office_hours: [values.officeHoursFrom, values.officeHoursTo],
    profile_image: values.profileImage,
    cover_image: values.coverImage,
    verification_information: values.verificationInformation,
  })
  return response
}

// export const removeAttachment = (id:number) => axios.delete(`supplier/company/attachment/${id}`)
