/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage} from 'formik'
import {Row, Col} from 'react-bootstrap-v5'
import CreatableSelect from 'react-select/creatable'
import {toast} from 'react-toastify'

import {activities} from '../../../../utils/mock/activities'
import {convertToBase64} from '../../../../_helpers/convertToBase64'

const CompanyLogoField = () => {
  const handleFileUpload = async (e, setFieldValue) => {
    const file = e.target.files[0]
    if (file && file?.size / 1024 / 1024 < 2) {
      const base64 = await convertToBase64(file)
      setFieldValue('companyImage', base64)
    } else {
      toast.error('Image size must be of 2MB or less')
      setFieldValue('companyImage', '')
    }
  }
  return (
    <>
      <label className='required'>Company Logo</label>
      <Field name='companyImage'>
        {({form}) => {
          const {setFieldValue} = form
          return (
            <input
              type='file'
              className='form-control'
              accept='.jpeg, .png, .jpg'
              onChange={(e) => handleFileUpload(e, setFieldValue)}
            />
          )
        }}
      </Field>
      <div className='text-danger mt-2'>
        <ErrorMessage name='companyImage' />
      </div>
      <div className='form-text'>Please add a file of less than of 2MB.</div>
    </>
  )
}

const CompanyDetail = () => {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: state.isFocused ? 'none' : 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  }

  const DropdownIndicator = () => null

  return (
    <div className='w-100'>
      <div className='mb-10'>
        <h2>Company Details</h2>
      </div>
      <div className='company-details-form'>
        {/* company contact */}
        <div className='company-contact mb-10 fv-row'>
          <label className='form-label mb-3 required'>
            Company Contact Number or Land Line number
          </label>
          <Field
            type='string'
            className='form-control form-control-lg form-control-solid'
            name='companyNumber'
            placeholder='Enter Your Contact Number'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='companyNumber' />
          </div>
        </div>
        {/* company activities */}
        <div className='mb-10 fv-row acitivities'>
          <label className='form-label mb-3'>Company Activities (optional)</label>
          <Field name='activity mb-10'>
            {({form, field}) => {
              const {setFieldValue} = form
              return (
                <CreatableSelect
                  className='form-select form-select-solid border-0'
                  isClearable
                  isMulti
                  options={activities}
                  styles={customStyles}
                  components={{
                    DropdownIndicator,
                  }}
                  onChange={(val) => {
                    return setFieldValue('activity', val)
                  }}
                />
              )
            }}
          </Field>
          <div className='text-danger'>
            <ErrorMessage name='activity' />
          </div>
        </div>
        {/* company website address */}
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3'>Company Website</label>
          <Field
            type='link'
            className='form-control form-control-lg form-control-solid'
            name='website'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='website' />
          </div>
          <div className='form-text'>http://www.example.com</div>
        </div>
        {/* company offers hours */}
        <div className='office-hours'>
          <div className='mb-10'>
            <Row>
              <label className='form-label required'>Company Office Hours</label>
              <Col>
                <Field
                  type='time'
                  className='form-control form-control-lg form-control-solid'
                  name='officeHoursFrom'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='officeHoursFrom' />
                </div>
              </Col>
              <Col>
                <Field
                  type='time'
                  className='form-control form-control-lg form-control-solid'
                  name='officeHoursTo'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='officeHoursTo' />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* company logo */}
        <div className='mb-10'>
        <CompanyLogoField />
        </div>
      </div>
    </div>
  )
}

export default CompanyDetail
