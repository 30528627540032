/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage, useFormikContext} from 'formik'

const GeneralInfo = () => {
  const {setFieldValue} = useFormikContext()

  // changing the company officehours accordingly to companyType
  const changeOfficeHours = (companyType) => {
    setFieldValue('companyType', companyType)
    if (companyType.trim() !== '' && companyType.trim() !== ''){
      if (companyType === 'hotel') {
        setFieldValue('officeHoursFrom', '00:00')
        setFieldValue('officeHoursTo', '23:59')
      } else if (companyType === 'travel') {
        setFieldValue('officeHoursFrom', '09:00')
        setFieldValue('officeHoursTo', '18:00')
      }
    }
  }

  return (
    <div className='w-100'>
      <div className='mb-10'>
        <h2>User Registration</h2>
      </div>
      {/* company name */}
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Company Name</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='companyName'
          // required
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>
      {/* company type */}
      <div className='mb-10 fv-row company-type'>
        <label className='form-label mb-3 required'>Company Type</label>
        <Field name='companyType'>
          {() => (
            <select
              name='companyType'
              onChange={(event) => changeOfficeHours(event.target.value)}
              className='form-select form-select-solid'
            >
              <option value=''>Choose</option>
              <option value='hotel'>Hotel</option>
              <option value='travel'>Travel</option>
            </select>
          )}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyType' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Email Address</label>
        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name='emailAddress'
          placeholder='Enter your email address'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='emailAddress' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Password</label>
        <Field
          type='password'
          className='form-control form-control-lg form-control-solid'
          name='password'
          placeholder='Enter your password'
          // required
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='password' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Confirm Password</label>
        <Field
          type='password'
          className='form-control form-control-lg form-control-solid'
          name='confirmPassword'
          placeholder='Enter your password'
          // required
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='confirmPassword' />
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo
