import React, {useState} from 'react'
import {Field, useFormikContext} from 'formik'
import {useRemoveAttachment} from '../hooks/useRemoveAttachment'
import ModalView from '../pages/modals/DeleteItem'

import {convertToBase64} from '../_helpers/convertToBase64'

type Props = {
  image? : any,
  name : string
}

const ImagePicker = (props: Props) => {
  const [show, setShow] = useState<boolean>(false)
  const [isSizeError, isSetSizeError] = useState<boolean>(false)
  const {setFieldValue} = useFormikContext()
  const mutation = useRemoveAttachment([])
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const removeImage = () => {
    mutation.mutate(props.image.id , {
        onSuccess : () => {
            handleClose()
        }
    })
  }

  return (
    <>
      {props.image !== null ? (
        <>
          <div className='thumbImage'>
            <img className='w-100 h-10' src={props.image?.url || props?.image} alt='coverImage' />
            <div className='overlay'></div>
            <button
              type='button'
              className='packageCancelButton'
              onClick={handleShow}
              // disabled={mutation.isLoading}
            >
              x
            </button>
          </div>
          {show && (
            <ModalView
              show={show}
              isLoading={mutation.isLoading}
              remove={removeImage}
              handleClose={handleClose}
              handleShow={handleShow}
            />
          )}
        </>
      ) : (
        <Field name={props.name}>
          {() => {
            return (
              <>
                <input
                  type='file'
                  className='form-control'
                  accept='image/jpeg, image/png , image/jpg'
                  onChange={async (e: any) => {
                    const file = e.target.files[0]
                    if (file?.size / 1024 / 1024 < 2) {
                      const base64 = await convertToBase64(file)
                      setFieldValue(props.name, base64)
                      isSetSizeError(false)
                    } else {
                      isSetSizeError(true)
                    }
                  }}
                  required={props.image ? false : true}
                />
                <span className='text-danger'>
                  {isSizeError && `Image size must be of less than 2MB`}
                </span>
              </>
            )
          }}
        </Field>
      )}
    </>
  )
}

export default ImagePicker
