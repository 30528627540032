import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {FieldArray} from 'formik'

import {useUploadAttachment} from '../../hooks/useUploadAttachment'
import {convertToBase64} from '../../utils/covertToBase64'
import {useRemoveAttachment} from '../../hooks/useRemoveAttachment'
import {Button, Modal} from 'react-bootstrap-v5'

interface IImage {
  name: string
  url: string
  id: number
  type: string
}

type Props = {
  name?: string
  invalidateQuery: any
  files: any
}

const MultipleImageUploader = (props: Props) => {
  const {name = 'images', invalidateQuery, files} = props
  const [show, setShow] = useState<boolean>(false)
  const [selectedFileId, setSelectedFileId] = useState<any>(null)
  const {mutate, isLoading} = useUploadAttachment()
  const removeImageMutation = useRemoveAttachment(invalidateQuery)

  const handleClose = (): void => {
    setShow(false)
    setSelectedFileId(null)
  }
  const handleShow = (fileId: number): void => {
    setShow(true)
    setSelectedFileId(fileId)
  }
  const uploadImage = async (e: any, setFieldValue: any, value: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = []
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i]?.size / 1024 / 1024 < 2) {
          newImagesPromises.push(convertToBase64(e.target.files[i]))
        }
      }
      const newImages: any = await Promise.all(newImagesPromises)
      mutate(newImages, {
        onSuccess: (data) => {
          toast.success(`Image posted successfully`)
          setFieldValue(name, data?.data)
        },
      })
    }
  }
  const removeImage = (): void => {
    removeImageMutation.mutate(selectedFileId, {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  return (
    <div className='form-group'>
      <FieldArray name={name}>
        {(fieldArrayProps: any) => {
          const {form} = fieldArrayProps
          const {setFieldValue, value} = form
          return (
            <input
              type='file'
              accept='image/jpg, image/jpeg, image/png'
              multiple
              className='form-control'
              onChange={(e) => uploadImage(e, setFieldValue, value)}
            />
          )
        }}
      </FieldArray>
      <div className='form-text'>Please add a file of less than of 2MB.</div>
      <div className='overlay overlay-block rounded card '>
        {isLoading && (
          <div className='overlay-layer rounded bg-dark bg-opacity-5'>
            Uploading image please wait...
            <div className='spinner-border text-primary mx-2' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap mt-3'>
        {files?.length > 0 &&
          files?.map((file: IImage) =>
            file?.type?.toLowerCase() === 'verification' ||
            file?.type?.toLowerCase() === 'gallery' ? (
              <div className='thumbImage m-2' key={file.url}>
                <img className='w-100 h-10' src={file.url} alt={file?.name} />
                <div className='overlay'></div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <Modal.Header>
                    <Modal.Title>Remove selected items</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                  <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant='danger' onClick={removeImage}>
                      <span className='indicator-label'>
                        {!removeImageMutation.isLoading && (
                          <span className='indicator-label'>Remove</span>
                        )}
                        {removeImageMutation.isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            removing...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </span>
                    </Button>
                  </Modal.Footer>
                </Modal>
                <button
                  type='button'
                  className='packageCancelButton'
                  onClick={() => handleShow(file.id)}
                >
                  x
                </button>
              </div>
            ) : null
          )}
      </div>
    </div>
  )
}

export default MultipleImageUploader
