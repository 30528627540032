import React from 'react'
import { Link } from 'react-router-dom'

import { PageTitle } from '../../../_metronic/layout/core'
import { usePaymentRequests } from '../../hooks/usePaymentRequests'
import { useUserRole } from '../../hooks/useUserRole'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'
import { ROLE } from '../../_helpers/Role'
import { LoadingView } from '../LoadingView'
import PaymentRequestItem from './PaymentRequestItem'

const paymentBreadCrumbs = [
  {
    title: 'Payment',
    path: '/payment-requests',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentRequests = () => {

  useUserRole([ROLE.TravelSupplier, ROLE.HotelSupplier, ROLE.Admin]);

  const [page, setPage] = React.useState(1);

  const [status, setStatus] = React.useState('');

  const {
    data: paymentRequests,
    isFetching,
    isError,
  } = usePaymentRequests({page, status});

  if (isFetching) {
    return <LoadingView />
  };

  if (isError) {
    return <ErrorsPage />
  };

  return (
    <>
      <PageTitle breadcrumbs={paymentBreadCrumbs}>Payment Requests</PageTitle>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Payment Requests</span>
            <span className='text-muted mt-1 fw-bold fs-7'>All Payment Requests</span>
          </h3>

          {/* <!--begin::Trigger--> */}
          {/* <CDropdown items={items} /> */}
          {/* <!--end::Menu--> */}

          <div className='card-toolbar'>
            <div className='me-4'>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value=''>All</option>
                <option value='completed'>Completed</option>
                <option value='pending'>Pending</option>
                <option value='cancelled_by_supplier'>Cancelled</option>
              </select>
            </div>
            <Link to='/payment-request/add'>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                Add Payment Requests
                {/* <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' /> */}
              </button>
            </Link>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            ></div>
          </div>
        </div>
        {paymentRequests?.data?.length > 0 ? (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='w-25px text-captalise'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-13-check'
                        />
                      </div>
                    </th>
                    <th className='min-w-120px'>Id</th>
                    <th className='min-w-120px'>Account Name</th>
                    <th className='min-w-120px'>Account Number</th>
                    <th className='min-w-120px'>Account Branch</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='min-w-120px'>Amount</th>
                    <th className='min-w-120px'>Paid Amount</th>
                    <th className='min-w-140px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentRequests?.data?.map((item: any, index: any) => (
                    <PaymentRequestItem item={item} index={index} key={item.id} />
                  ))}
                </tbody>
              </table>
            </div>
            {/* pagination */}
            <div className='d-flex flex-stack flex-wrap pt-10'>
              <div className='fs-6 fw-bold text-gray-700'>
                Showing {page} of {paymentRequests?.last_page} products
              </div>
              <div className='fs-6 fw-bold text-gray-700'></div>
              <ul className='pagination'>
                <li className='page-item previous'>
                  <button
                    className='page-link'
                    onClick={() => setPage((page) => page - 1)}
                    disabled={page === 1}
                  >
                    <i className='previous'></i>
                  </button>
                </li>
                <li className='page-item active'>
                  <button className='page-link'>{page}</button>
                </li>
                <li className='page-item next'>
                  <button
                    className='page-link'
                    onClick={() => setPage((page) => page + 1)}
                    disabled={page === paymentRequests?.last_page}
                  >
                    <i className='next'></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <h4>No payment requests to show</h4>
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentRequests
