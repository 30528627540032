import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {useMutation} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

import {KTSVG} from '../../../../_metronic/helpers'
import GeneralInfo from './registrationSteps/GeneralInfo'
import MainContact from './registrationSteps/MainContact'
import CompanyDetail from './registrationSteps/CompanyDetail'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {createAccountSchemas, inits} from './registrationSteps/RegistrationWizard'
import VerificationStep from './registrationSteps/VerificationSteps'
import {authenticationService} from '../../../services/api/authentication'

const RegistrationProcess: FC = () => {
  const history = useHistory()
  const editRegistration = useMutation(authenticationService.editSupplierById, {
    onSuccess: () => {},
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })
  const registrationMutation = useMutation(authenticationService.register)
  const [userId, setUserId] = useState<number | null>(null)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<any>(inits)
  // const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    // setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const nextStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goNext()
  }

  const submitStep = (values: any, actions: FormikValues) => {
    console.log('stepper.current.currentStepIndex' , stepper?.current?.currentStepIndex);
    
    if (!stepper.current) {
      return
    }
        
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== 1 && userId) {
      editRegistration.mutate(
        {...values, userId},
        {
          onSuccess: () => {
            if (!stepper.current) {
              return
            }
            if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
              nextStep()
            } else {
              toast.success('Company detailed added successfully')
              history.push(`/auth/login`)
            }
          },
          onError: (error) => {
            toast.error(`${error?.response?.data?.error?.message}`)
          },
        }
      )
    } else {
      registrationMutation.mutate(
        {...values},
       {
          onSuccess: (data) => {
            setUserId(data.data.company.id)
            toast.success('User created successfully')
            nextStep()
          },
          onError: (error: any) => {
            toast.error(`${error?.response?.data?.error?.message}`)
            prevStep()
          },
        }
      )
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  
  return (
    <div className='card'>
      <div className='text-center'>
        <h1 className='text-dark mb-3'> Create an Supplier Account</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an Account?{' '}
          <Link to='/auth/login' className='link-primary fw-bolder'>
            Sign In Instead
          </Link>
        </div>
      </div>
      <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column pt-15'
        id='kt_create_account_stepper'
      >
        <div className='stepper-nav flex-nowrap'>
          <div className='stepper-item current' data-kt-stepper-element='nav'>
            <h6 className='stepper-title' style={{fontSize: '1rem'}}>
              User Registration
            </h6>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h6 className='stepper-title' style={{fontSize: '1rem'}}>
              Company Details
            </h6>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h6 className='stepper-title' style={{fontSize: '1rem'}}>
              Main Contact
            </h6>
          </div>
          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h6 className='stepper-title' style={{fontSize: '1rem'}}>
              Verification
            </h6>
          </div>
        </div>

        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <GeneralInfo />
              </div>

              <div data-kt-stepper-element='content'>
                <CompanyDetail />
              </div>

              <div data-kt-stepper-element='content'>
                <MainContact />
              </div>

              <div data-kt-stepper-element='content'>
                <VerificationStep />
              </div>
              <p className='fw-light my-2'>
                Please make sure to read the{' '}
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/terms-condition`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  terms and condition
                </a>{' '}
                before starting and ensure that the company meets our requirements and standards.
              </p>
              <div className='d-flex flex-stack pt-15'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    disabled={stepper.current?.currentStepIndex === 2}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <>
                    {userId && (
                      <button
                        type='submit'
                        className='btn btn-lg btn-light-primary me-3'
                        onClick={() => history.push('/auth/login')}
                      >
                        <span className='indicator-label'>
                          <span className='indicator-label'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr062.svg'
                              className='svg-icon-4 me-1'
                            />
                            Skip
                          </span>
                        </span>
                      </button>
                    )}
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        <span className='indicator-label'>
                          {editRegistration.isLoading || registrationMutation.isLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Submiting...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            'Save & Continue'
                          )}
                        </span>
                      </span>
                    </button>
                  </>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {RegistrationProcess}
