import  {ErrorMessage, FieldArray} from 'formik'
import {toast} from 'react-toastify'
import { useGuestUploadAttachment } from '../../../../hooks/useGuestUploadAttachment'
import {convertToBase64} from '../../../../_helpers/convertToBase64'

const VerificationStep = () => {

  const {mutate, isLoading} = useGuestUploadAttachment()

  const uploadImage = async (e: any, setFieldValue: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = []
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(convertToBase64(e.target.files[i]))
      }
      const newImages: any = await Promise.all(newImagesPromises)
      mutate(newImages, {
        onSuccess: (data) => {
          toast.success(`Image posted successfully`)
          setFieldValue('verificationDocuments', data?.data)
        },
      })
    }
  }

  return (
    <div className='w-100'>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Upload verification documents</label>
        <FieldArray name='verificationDocuments'>
          {(fieldArrayProps: any) => {
            const {form} = fieldArrayProps
            const {setFieldValue} = form
            return (
              <input
                type='file'
                accept='image/jpeg, image/png , image/jpg'
                multiple
                className='form-control'
                onChange={(e) => uploadImage(e, setFieldValue)}
              />
            )
          }}
        </FieldArray>
        <div className='text-danger'>
          <ErrorMessage name='verificationDocuments' />
        </div>
        <div className="form-text">Please add a file of less than of 2MB and documents like VAT , PAN and company registered images.</div>
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Submiting...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </div>
    </div>
  )
}
export default VerificationStep
