import axios from '../../../setup/axios/SetupAxios'

/* ******************************************
 *************** Offer price **************
 *********************************************/

const getOfferPrices = async (status:string , page: number) => {
  const {data} = await axios.get(`/supplier/offers?status=${status}&page=${page}`)
  return data
}

const getOfferPrice = async (id: number) => {
  const {data} = await axios.get(`/supplier/offers/${id}`)
  return data
}

const postOfferPrice = async ({...values}: any) => {
  const response = await axios.post(`/supplier/${values.productType}/${values.productType === "rooms" ? values.roomSlug : values.packageSlug}/offers`, {
    offer_price: values.offerPrice,
    actives_at: values.activesAt,
    expires_at: values.expiresAt,
    campaign_code : values.campaignCode
  })
  return response
}

const editOfferPrice = async ({id, ...values}: any) => {
  const response = await axios.put(`/supplier/${values.productType}/${values.productType === "rooms" ? values.roomSlug : values.packageSlug}/offers/${id}`, {
    offer_price: values.offerPrice,
    actives_at: values.activesAt,
    expires_at: values.expiresAt,
    campaign_code : values.campaignCode
  })
  return response
}

const deleteOfferPrice = async (id: number) => {
  const response = await axios.delete(`/supplier/offers/${id}`)
  return response.data
}

const getOfferPriceProducts = async () => {
  const {data} = await axios.get(`/supplier/offers/products`)
  return data
}

export const offerServices = {
  getOfferPrices,
  postOfferPrice,
  editOfferPrice,
  deleteOfferPrice,
  getOfferPrice,
  getOfferPriceProducts,
}
