/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { KTSVG } from '../../../../_metronic/helpers'
import { couponServices } from '../../../services/api/coupon'
import { formatDate } from '../../../utils/formatDate'
import ModalView from '../../modals/DeleteItem'

const CouponItem = ({ item, index }: any) => {

  const id = item.id

  const queryClient = useQueryClient()

  const [show, setShow] = React.useState(false)

  const handleShow = () => setShow(true)

  const handleClose = () => setShow(false)

  const mutation = useMutation(couponServices.deleteCoupon, {
    onSuccess: (data: any) => {
      handleClose()
      queryClient.invalidateQueries('coupons')
      toast.success('Coupon deleted successfully')
    },
    onError: (data: any) => {
      handleClose()
      queryClient.invalidateQueries('coupons')
      toast.error('Error while deleting')
    },
  })

  const removeCoupon = () => {
    mutation.mutate(id, {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries('coupon')
      },
      onError: () => {
        handleClose()
        toast.error('Please try again')
      },
    })
  }

  return (
    <tr key={item.id}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-13-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
          {index + 1}
        </a>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'>
              {item.name}
              <span
                className={`badge badge-light-${item?.is_active ? 'success' : 'warning'
                  } fs-9 fw-bolder m-2`}
              >
                {item.is_active ? 'active' : 'inactive'}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item.coupon_code}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item.discount_type}
        </a>
      </td>
      <td className='text-dark fw-bolder text-hover-primary fs-6'>{formatDate(item.expires_at)}</td>
      <td>
        <Link
          to={`/offers/coupon/${item.id}/edit`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <a
          onClick={handleShow}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
        <ModalView
          slug={id}
          show={show}
          isLoading={mutation.isLoading}
          remove={removeCoupon}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </td>
    </tr>
  )
}

export default CouponItem
