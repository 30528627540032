import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'

import { PageTitle } from '../../../../_metronic/layout/core'
import CouponForm from './CouponForm'
import { CouponSchema } from './CouponSchema'
import { useUser } from '../../../hooks/useUser'
import { couponServices } from '../../../services/api/coupon'
import moment from 'moment'
import { FallbackView } from '../../../../_metronic/partials'
import { Error500 } from '../../../modules/errors/components/Error500'
import { supplierProducts } from '../../../services/api/products'
import { useUserRole } from '../../../hooks/useUserRole'
import { ROLE } from '../../../_helpers/Role'

const couponBreadCrumbs = [
  {
    title: 'Coupon',
    path: '/coupon',
    isSeparator: false,
    isActive: false,
  },
]

const AddEditCoupon = () => {
  useUserRole([ROLE.HotelSupplier , ROLE.TravelSupplier]);
  const history = useHistory()
  const user = useUser()
  const params: any = useParams()
  const queryClient = useQueryClient()
  const id: number = Number(params?.id)
  const userType = user?.type.toLowerCase() === "travel" ?  "packages" : "rooms"

  const isAddMode = !id

  const { data, isLoading, isError } = useQuery(['coupon', id], () => couponServices.getCoupon(id), {
    enabled: !!id,
  })

  const {data: products, isLoading: isProductLoading} = useQuery(['products'], supplierProducts.getSupplierProducts)

  const addMutation: any = useMutation(couponServices.postCoupon, {
    onSuccess: () => {
      toast.success('Coupon added successfully')
      queryClient.invalidateQueries('coupons')
      history.push(`/offers/coupon`)
    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })
  const editMutation: any = useMutation(couponServices.editCoupon, {
    onSuccess: () => {
      toast.success('Coupon edited successfully')
      queryClient.invalidateQueries(['coupon', id])
    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })

  const onFormSubmit = (values: any) => {
    isAddMode
      ? addMutation.mutate({ ...values })
      : editMutation.mutate(
        { ...values, id },
        {
          onSuccess: (data: any) => {
            history.push(`/offers/coupon`)
          },
        }
      )
  }

  if (isLoading || isProductLoading) return <FallbackView />
  if (isError) return <Error500 />

  let couponableId = data?.all_couponable?.map((item: any) => data?.couponable_type !== "users" ? item.couponable_id : 0)
  
  const selectedHotel = products?.find((hotel: any) => hotel.id === parseInt(data?.all_couponable[0]?.property_id))

  const initValues = {
    userType,
    name: data?.name,
    couponType: isAddMode ? '' :  data?.couponable_type,
    rooms : selectedHotel?.rooms,
    couponableId: isAddMode ? '' : couponableId,
    couponCode: data?.coupon_code,
    discountType: data?.discount_type || 'fixed',
    discountValue: data?.discount_value,
    discountPercent: data?.discount_percent,
    maxDiscountCap: data?.max_discount_cap,
    minimumSpend: data?.minimum_spend,
    couponUsageLimit: data?.coupon_usage_limit,
    perUserLimit: data?.per_user_limit || 1,
    isGuestAllowed: Boolean(data?.is_guest_allowed),
    activesAt: isAddMode ? '' : moment(data?.actives_at).format('YYYY-MM-DD'),
    expiresAt: isAddMode ? '' : moment(data?.expires_at).format('YYYY-MM-DD'),
    campaignCode : isAddMode ? '' : data?.campaigns[0]?.code,
    property : isAddMode ? '' : data?.all_couponable[0]?.property_id,
    isSupplierGlobal : isAddMode ? '' : data.couponable_type === "users" ? 'all' : 'selected',
    remarks : isAddMode ? '' :  data.remarks
  }

  return (
    <>
      <PageTitle breadcrumbs={couponBreadCrumbs}>{isAddMode ? "Add Coupon" : "Edit Coupon"}</PageTitle>
      <div className=''>
        <h6>Coupon Information</h6>
        <Formik validationSchema={CouponSchema} initialValues={initValues} onSubmit={onFormSubmit}>
          {(props) => (
            <Form className='w-100 pb-10'>
              <CouponForm values={props} products={products} userType={userType}/>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-10 my-5'
                disabled={addMutation.isLoading || editMutation.isLoading}
              >
                {addMutation.isLoading || editMutation.isLoading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Submit'
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default AddEditCoupon
