/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState} from 'react'

import Pagination from '../../../components/Pagination'
import useBookings from '../../../hooks/useBookings'
import {useUserRole} from '../../../hooks/useUserRole'

import {ROLE} from '../../../_helpers/Role'
import {ErrorsPage} from '../../../modules/errors/ErrorsPage'
import {LoadingView} from '../../LoadingView'
import {PRODUCT_TYPE} from '../../../constants/product-type'
import {useProductType} from '../../../hooks/useProductType'
import BookingItem from './BookingItem'

const Bookings = ({className}: any) => {
  useUserRole([ROLE.TravelSupplier, ROLE.HotelSupplier, ROLE.Admin])
  const [page, setPage] = useState(1)
  const [status, setStatus] = React.useState('')
  const {getSupplierBookings} = useBookings(status, page)
  const type = useProductType()
  const {data: bookings, isFetching, isError}: any = getSupplierBookings

  if (isFetching) {
    return <LoadingView />
  }
  if (isError) {
    return <ErrorsPage />
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Recent Bookings</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            Over {bookings?.data?.length} Bookings
          </span>
        </h3>
        {/* search filter starts */}
        <div className='card-toolbar'>
          <div className='me-4'>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={''}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value=''>All</option>
              <option value='approved'>Approved</option>
              <option value='pending'>Pending</option>
              <option value='cancelled'>Cancelled</option>
              <option value='cancellation_approved'>Cancellation Approved</option>
              <option value='cancellation_pending'>Cancellation Pending</option>
            </select>
          </div>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          ></div>
        </div>
        {/* search filter ends */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {bookings?.data?.length > 0 ? (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th>
                  <th className='min-w-50px'>Id</th>
                  <th className='min-w-300px'>
                    {type === PRODUCT_TYPE.HOTEL ? 'Property / Rooms' : 'Package'} Title
                  </th>
                  <th className='min-w-140px'>Booked by</th>
                  <th className='min-w-100px'>Total Amount</th>
                  <th className='min-w-100px'>Starts At</th>
                  {/* <th className='min-w-100px'>Ends At</th> */}
                  <th className='min-w-100px'>Booked At</th>
                  {/* <th className='min-w-75px'>Is Paid</th> */}
                  <th className='min-w-100px'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {bookings?.data?.map((booking: any, index: number) => (
                  <BookingItem key={booking.id} bookingDetail={booking} index={index} />
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            <Pagination page={page} setPage={setPage} lastPage={bookings.last_page} />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center my-5'>
          <h4>No Bookings to show</h4>
        </div>
      )}
      {/* begin::Body */}
    </div>
  )
}

export default Bookings
