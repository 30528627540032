import { Carousel } from "react-responsive-carousel"
import { useParams } from "react-router-dom"

import { PageTitle } from "../../../_metronic/layout/core"
import { FallbackView } from "../../../_metronic/partials"
import { usePayementRequest } from "../../hooks/usePaymentRequest"

const profileBreadCrumbs = [
    {
        title: 'Payment Requests',
        path: '/payment-requests',
        isSeparator: false,
        isActive: false,
    },
];

const ViewPaymentRequest = () => {

    const params: {id:string} = useParams();

    const id = Number(params.id);

    const { data, isLoading } = usePayementRequest(id);

    if (isLoading) return <FallbackView />;

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>View Payment requests</PageTitle>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='card-body p-9'>
                            <h4>Payment requests</h4>
                            <div className='row my-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Request Amount: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>Rs. {data?.request_amount}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Paid Amount: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>Rs. {data?.paid_amount}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Transaction number: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>
                                        {data?.txn_number || '-'}
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Payment Date Time : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.payment_date_time || ""}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Supplier Name : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.supplier.name}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Supplier Type : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.supplier.type}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Status : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.status}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Note : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.note || ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Carousel>
                            {data?.files?.map((item: any, index: number) => (
                                <img
                                    key={index}
                                    src={item?.url}
                                    alt={item?.original_name}
                                    className='img-fluid mx-2'
                                    style={{ aspectRatio: '16 / 9 ', width: '100%', objectFit: 'cover' }}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card-body p-9'>
                            <h4>Payment Method</h4>
                            <div className='row my-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Payment method: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.payment_method.method}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Method Aame: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.payment_method.method_name}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Account Branch: </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>
                                        {data?.payment_method.account_branch || "-"}
                                    </span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Account Name : </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{data?.payment_method.account_name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewPaymentRequest
