import {useEffect} from 'react'
import { toast } from 'react-toastify'

import {useAuth} from './useAuth'

export const useUserRole = (allowedRoles) => {
  const {session} = useAuth()
  useEffect(() => {
    if (!allowedRoles.includes(session?.user?.role)) {
      localStorage.removeItem('session')
      window.location.reload()
      toast.error(`Unauthorized`)
    }
  }, [allowedRoles , session])
  return true
}