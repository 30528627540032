import React from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'

import {useParamsQuery} from '../../../hooks/useParamsQuery'
import {authenticationService} from '../../../services/api/authentication'
import {Link} from 'react-router-dom'

var regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(255, 'Maximum 255 characters')
    .matches(
      regx,
      'Password must be at least 8 characters long including at least one UPPERCASE, one lowercase, one number and one special character'
    )
    .required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password is required'),
})

const initialValues = {
  password: '',
  password_confirmation: '',
}

export function ResetPassword() {
  let query = useParamsQuery()

  const history = useHistory()

  const email: string | null = query.get('email')

  const token: string | null = query.get('token')

  const mutation = useMutation(authenticationService.resetPassword)

  const onFormSubmit = (value: any) => {
    const {password, password_confirmation} = value
    mutation.mutate(
      {email, token, password, password_confirmation},
      {
        onSuccess: () => {
          history.push(`/auth/login`)
          toast.success(`Password has been reset successfully`)
        },
        onError: (error: any) => {
          toast.error(`${error?.response?.data?.error?.message}`)
        },
      }
    )
  }
  return (
    <>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Reset Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>Enter password to reset your password.</div>
        {/* end::Link */}
      </div>
      <Formik
        validationSchema={resetPasswordSchema}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {() => (
          <Form>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>

            <Field
              name='password'
              type='password'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter your password'
            />
            <div className='text-danger'>
              <ErrorMessage name='password' />
            </div>
            <label className='form-label fw-bolder text-gray-900 fs-6 mt-5'>Confirm password</label>
            <Field
              name='password_confirmation'
              type='password'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter your confirm password'
            />
            <div className='text-danger'>
              <ErrorMessage name='password_confirmation' />
            </div>
            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-8'>
              {' '}
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-10'
                disabled={mutation.isLoading}
              >
                {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                {mutation.isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder ms-4'
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </Form>
        )}
      </Formik>
    </>
  )
}
