/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

import { KTSVG } from '../../../_metronic/helpers'
import { useDeletePaymentRequests } from '../../hooks/useDeletePaymentRequests'
import { statusCheck } from '../../_helpers/statusCheck'
import ModalView from '../modals/DeleteItem'

const PaymentRequestItem = (props: any) => {

  const { index, item } = props

  const [show, setShow] = React.useState(false)

  const handleShow = () => setShow(true)

  const handleClose = () => setShow(false)

  const mutation = useDeletePaymentRequests(handleClose);

  const removePaymentMethod = () => {
    mutation.mutate(item.id)  
  };

  return (
    <tr key={item.id}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-13-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
          {index + 1}
        </a>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <Link
              to={`/payment-requests`}
              className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'
            >
              {item?.payment_method?.method_name || "-"}
            </Link>
            {item?.primary && (
              <span className='badge badge-light-success badge-sm ms-4'>primary</span>
            )}
          </div>
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item?.payment_method?.account_number || "-"}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item?.payment_method?.account_branch || '-'}
        </a>
      </td>
      <td>{statusCheck(item.status)}</td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          Rs. {item?.request_amount}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          Rs. {item?.paid_amount || '-'}
        </a>
      </td>
      <td>
        {
          item.status === "completed" && (
            <Link
              to={`payment-request/${item.id}/view`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
            </Link>
          )
        }
        {
          item.status === "pending" && (
            <>
              <Link
                to={`/payment-request/edit/${item.id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </Link>
              <a
                onClick={handleShow}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </a>
              <ModalView
                slug={item.id}
                show={show}
                isLoading={mutation.isLoading}
                remove={removePaymentMethod}
                handleClose={handleClose}
                handleShow={handleShow}
              />
            </>
          )
        }
      </td>
    </tr>
  )
}

export default PaymentRequestItem
