/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { KTSVG } from '../../../_metronic/helpers'
import { paymentServices } from '../../services/api/payment'
import ModalView from '../modals/DeleteItem'

interface PaymentMethodType {
  index: number
  page: number
  item: {
    account_name: string
    account_number: number
    primary: boolean
    method: string
    method_name: string
    account_branch: any
    id: number
  }
}

const PaymentMethodItem = (props: PaymentMethodType) => {
  const { item, index } = props

  const queryClient = useQueryClient()
  const [show, setShow] = React.useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const mutation = useMutation(paymentServices.deletePaymentMethod, {
    onSuccess: (data: any) => {
      toast.success('Payment method deleted successfully')
      queryClient.invalidateQueries(['payment-methods'])
      handleClose()
    },
  })

  const removePaymentMethod = () => {
    mutation.mutate(item.id)
  }

  return (
    <tr key={item.id}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-13-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
          {index + 1}
        </a>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column' id='payment_method_toggle'>
            <Link
              to={`/payment-method`}
              className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'
            >
              {item.method_name}
            </Link>
          </div>
          {props?.item?.primary && (
            <span className='badge badge-light-success badge-sm ms-4'>primary</span>
          )}
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item.account_name || '-'}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item.account_number}
        </a>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {item.account_branch || '-'}
        </a>
      </td>
      <td>
        <Link
          to={`/payment-method/edit/${item.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <a
          onClick={handleShow}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
        <ModalView
          slug={item.id}
          show={show}
          isLoading={mutation.isLoading}
          remove={removePaymentMethod}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </td>
    </tr>
  )
}

export default PaymentMethodItem
