import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {actionServices} from '../services/actions/actionServices'

const BookingActions = (props: any) => {

  const {bookingReference, options} = props

  const bookingOptions = actionServices.bookingActions(props.checkStatus)
  
  return (
    <>
      <div>
        <label className='form-label fw-bold'>Actions :</label>
        <select
          className='form-select form-select-solid'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          defaultValue={'choose'}
          value={props.options}
          onChange={(e) => props.handleClick(e)}
        >
          <option value='choose' disabled>
            Choose your actions
          </option>
          {bookingOptions}
        </select>
      </div>
      <Modal show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Supplier Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to {props.options} the status</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={() => props.mutation.mutate({bookingReference, options})}
            disabled={props.mutation.isLoading}
          >
            {!props.mutation.isLoading && <span className='indicator-label'>Yes</span>}
            {props.mutation.isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
            {props.show}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BookingActions
