import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import { ROLE } from '../../../_helpers/Role'
import { ErrorsPage } from '../../../modules/errors/ErrorsPage'
import { LoadingView } from '../../LoadingView'
import { useUserRole } from '../../../hooks/useUserRole'
import CouponItem from './CouponItem'
import { couponServices } from '../../../services/api/coupon'
import SelectFilter from '../../../components/filter/SelectFilter'
import { offerOptions } from '../../../utils/mock/options'

const CouponList = ({ className }: any) => {

  useUserRole([ROLE.HotelSupplier, ROLE.TravelSupplier, ROLE.Admin])

  const [page, setPage] = React.useState(1)

  const [status, setStatus] = React.useState('')

  const {
    data: coupons,
    isFetching,
    isError,
  } = useQuery(['coupons', status, page], () => couponServices.getCoupons(status, page), {
    keepPreviousData: true,
  })

  if (isFetching) {
    return <LoadingView />
  }

  if (isError) {
    return <ErrorsPage />
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Coupons</span>
          <span className='text-muted mt-1 fw-bold fs-7'>All Coupons</span>
        </h3>
        {/* <!--begin::Trigger--> */}
        {/* <CDropdown items={items} /> */}
        {/* <!--end::Menu--> */}

        <div className='card-toolbar'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <SelectFilter status={status} setStatus={setStatus} options={offerOptions} />
            </div>
            <div>
              <Link to='/offers/coupon/add' className='ms-3'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  Add coupon
                  {/* <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' /> */}
                </button>
              </Link>
            </div>
          </div>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          ></div>
        </div>
      </div>
      {coupons?.data?.length > 0 ? (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-25px text-captalise'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th>
                  <th className='min-w-120px'>Id</th>
                  <th className='min-w-120px'>Name</th>
                  {/* <th className='min-w-120px'>Products</th> */}
                  <th className='min-w-120px'>Coupon code</th>
                  <th className='min-w-120px'>Discount type</th>
                  <th className='min-w-120px'>Coupon expiry date</th>
                  <th className='min-w-140px'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {coupons?.data?.map((item: any, index: any) => (
                  <CouponItem key={item?.id} item={item} index={index} />
                ))}
              </tbody>
            </table>
          </div>
          {/* pagination */}
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {page} of {coupons?.last_page} products
            </div>
            <div className='fs-6 fw-bold text-gray-700'></div>
            <ul className='pagination'>
              <li className='page-item previous'>
                <button
                  className='page-link'
                  onClick={() => setPage((page) => page - 1)}
                  disabled={page === 1}
                >
                  <i className='previous'></i>
                </button>
              </li>
              <li className='page-item active'>
                <button className='page-link'>{page}</button>
              </li>
              <li className='page-item next'>
                <button
                  className='page-link'
                  onClick={() => setPage((page) => page + 1)}
                  disabled={page === coupons?.last_page}
                >
                  <i className='next'></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center my-5'>
          <h4>No coupons to show</h4>
        </div>
      )}
    </div>
  )
}
export default CouponList
