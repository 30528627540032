import {useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useAuth} from '../../../../hooks/useAuth'
import {useQuery} from 'react-query'
import { toast } from 'react-toastify'
import { impersonateServices } from '../../../../services/api/admin/impersonateUser'

export const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const b64_to_utf8 = (token: any) => {
  return decodeURIComponent(escape(window.atob(token)))
}

export const useStringHook = () => {
  const {search} = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const ImpersonateUser = () => {
  const query = useStringHook()
  const history = useHistory()
  const {setAuth} = useAuth()

  const encodedToken = query.get('token') || ''
  if (!encodedToken) {
    history.push('/')
  }
  let token: any
  try {
    token = b64_to_utf8(encodedToken)
  } catch (err) {
    history.push('/')
  }

  useQuery(['impersonate-user', token], () => impersonateServices.impersonateSupplierLogin(token), {
    enabled: !!token,
    onSuccess: (data: any) => {
      //setting token in localstorage
      setAuth(data)
      history.push('/')
      toast.success(`User Logged in successfully`)
      window.location.reload()
    },
    onError: (error:any) => {
      toast.error(`${error.response.data.error.message}`)
      history.push('/')
    },
  })

  return true
}

export default ImpersonateUser
