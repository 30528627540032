import { ErrorMessage, Field, useFormikContext } from 'formik'
import { useState } from 'react';

import { KTSVG } from '../../../_metronic/helpers'
import { banksDetails } from '../../utils/mock/bankDetails'
import { useQuery } from 'react-query';
import { bankServices } from '../../services/api/admin/bankServices';
import { Spinner } from 'react-bootstrap-v5';

const PaymentMethodForm = () => {
  const { values, setFieldValue }: any = useFormikContext();
  const [radioValue, setRadioValue] = useState(values.method);
  const { data: banks, isLoading } = useQuery(['banks'], bankServices.getPublicBanks)

  const bankOptions = banks?.map((bank: any) => ({
    label: bank.name,
    value: bank?.name,
  }))

  return (
    <>
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select Account Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing / pament will be based on your account plan'
          ></i>
        </label>
        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTSVG
                    path='/media/icons/duotune/finance/fin001.svg'
                    className='svg-icon-1 svg-icon-gray-600'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>Banking</span>
                <span className='fs-6 fw-bold text-gray-400'>Payment transactions from bank</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                type='radio'
                name='method'
                value="bank"
                onChange={(e: any) => {
                  setRadioValue(e.target.value)
                  setFieldValue('method', 'bank')
                }} />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTSVG
                    path='/media/icons/duotune/graphs/gra006.svg'
                    className='svg-icon-1 svg-icon-gray-600'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>Wallet</span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Payment transactions from online devices
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                type='radio'
                name='method'
                value="wallet"
                onChange={(e: any) => {
                  setRadioValue(e.target.value)
                  setFieldValue('method', 'wallet')
                  setFieldValue('accountBranch', '')
                }} />
            </span>
          </label>
        </div>
      </div>
      <div className='text-danger'>
        <ErrorMessage name='method' />
      </div>
      <div className='mb-10'>
        <label className='form-label'>Method Name</label>
        <Field as='select' name='methodName' className='form-select' required>
          <option value=''>
            Choose
          </option>
          {radioValue === "bank" ? (
            isLoading ? <Spinner animation='grow' /> : (
              <>
                {
                  bankOptions.map((item: any, index: number) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))
                }
              </>
            )
          ) : (
            <>
              <option value='Khalti'>Khalti</option>
              <option value='E-sewa'>E-sewa</option>
            </>
          )}
        </Field>
        <div className='text-danger'>
          <ErrorMessage name='methodName' />
        </div>
      </div>
      {radioValue === "bank" && (
        <div className='mb-10'>
          <label className='form-label'>Account Branch</label>
          <Field
            type='text'
            className='form-control'
            placeholder='Account Branch'
            name='accountBranch'
          />
          <div className='text-danger'>
            <ErrorMessage name='accountBranch' />
          </div>
        </div>
      )}
      <div className='mb-10'>
        <label className='form-label'>Account Number</label>
        <Field
          type='text'
          className='form-control'
          placeholder='Account Number'
          name='accountNumber'
        />
        <div className='text-danger'>
          <ErrorMessage name='accountNumber' />
        </div>
      </div>
      <div className='mb-10'>
        <label className='form-label'>Account Name</label>
        <Field type='text' className='form-control' placeholder='Account Name' name='accountName' />
        <div className='text-danger'>
          <ErrorMessage name='accountName' />
        </div>
      </div>
      <div className='mb-10'>
        <label className='form-label'>Primary ?</label>
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <Field className='form-check-input' type='checkbox' name='primary' />
        </div>
        <div className='text-danger'>
          <ErrorMessage name='primary' />
        </div>
      </div>
    </>
  )
}

export default PaymentMethodForm
