import { useQuery } from "react-query"
import { offerServices } from "../services/api/offerPrice"

export const useOffer = (id: number) => {
    return useQuery(
        ['offer', id],
        () => offerServices.getOfferPrice(id),
        {
            enabled: !!id,
        }
    );
}