import axios from "../../../setup/axios/SetupAxios"

const getSupplierProducts = async() => {
    const {data} = await axios.get(`/supplier/products`)
    return data
}

// const getSupplier

export const supplierProducts = {
    getSupplierProducts
}