import * as Yup from 'yup'

import { phoneRegression } from '../../../utils/phoneRegression'
import { regression } from '../../../utils/regression';

export interface SupplierProfileDetails {
    companyName: string,
    email: string,
    companyType: string,
    website: string | any,
    activity: string[],
    directorFirstName: string,
    directorLastName: string,
    address: string,
    city: string,
    state: string,
    country: string,
    currency: string,
    zipCode: number,
    social: {
        facebook: string,
        instagram: string,
        twitter: string
    },
    phoneNumber: number,
    contactPerson: string,
    contactPersonPhone: number,
    officeHoursFrom: number,
    officeHoursTo: number,
    companyImage: string,
    profileImage: string,
    verificationInformation: string[],
};

export const ProfileDetailsSchema = Yup.object().shape({
    companyName: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(255, 'Maximum 255 symbols')
        .required("Company Name is required"),
    email: Yup.string().email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required').label('Email Address'),
    companyType: Yup.string().required("Company Type is required").label('CompanyType'),
    website: Yup.string()
        .matches(regression.URLRegx, 'Enter a valid url eg: https://www.example.com')
        .nullable().label('Website').url('Enter a valid url eg: https://www.example.com'),
    directorFirstName: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required("Director First Name is required")
        .label('FirstName'),
    contactPerson: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required("Contact Person Name is required")
        .label('Contact Person'),
    contactPersonPhone: Yup.string()
        .min(10, "Invalide Phone Number")
        .max(10, "Enter only 10 digit number")
        .matches(phoneRegression, 'Contact Person Phone is not valid')
        .required("Contact Person Phone is required")
        .label('Contact Person Phone'),
    directorLastName: Yup.string().required("Director Last Name is required")
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .label('LastName'),
    phoneNumber: Yup.string()
        .min(5, "Enter minimum 5 digits number")
        .max(15, "Enter only 15 digits number")
        // .matches(phoneRegression, 'Phone number is not valid')
        .required("Phone number is required")
        .label('Phone Number'),
    address: Yup.string().required("Address is required")
        .min(3, 'Minimum 3 symbols')
        .max(255, 'Maximum 255 symbols')
        .label('Address'),
    city: Yup.string().required("City is required")
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .label('City'),
    // state: Yup.string().required("State is required").label('State'),
    // companyNumber: Yup.string()
    //     .min(5, "Company Number is not valid")
    //     .max(15, "Enter valid company number")
    //     .required("Company Number is required"),
    zipCode: Yup.string().min(5, "Minimum 5 characters").max(5, 'Maximum 5 characters').nullable().label('Zip Code'),
    officeHoursFrom: Yup.string().required('Field is required'),
    officeHoursTo: Yup.string().required('Field is required'),
    // social: Yup.object().shape({
    //     facebook: Yup.string()
    //         .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
    //         .nullable().url('Enter a valid url eg: https://www.example.com').label('Facebook'),
    //     twitter: Yup.string()
    //         .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
    //         .nullable().url('Enter a valid url eg: https://www.example.com').label('Twitter'),
    //     instagram: Yup.string()
    //         .matches(URLRegx, 'Enter a valid url eg: https://www.example.com')
    //         .nullable().url('Enter a valid url eg: https://www.example.com').label('Instagram'),
    //     // companyImage : Yup.mixed().required('Company Image is required').label('Company Image')
    //     // type: Yup.string().required('Verification Type is required').label('Type'),
    //     // number: Yup.string()
    //     // .label('Verification Number').nullable().max(20 ,'Maximum 20 numbers' ),
    //     // issued_date : Yup.date().notRequired().label('Issued Date'),
    //     // issued_authority : Yup.string().nullable().label('Issued Date'),
    //     documents: Yup.mixed()
    //         .required("documents image is required"),
    // }),
});