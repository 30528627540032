import axios from "../../../../setup/axios/SetupAxios"

export const getPublicBanks = async() => {
    const { data } = await axios.get('/banks')
    return data
}

const getBanks = async () => {
    const { data } = await axios.get('/admin/banks')
    return data
}

const getBank = async (slug: string) => {
    const { data } = await axios.get(`/admin/banks/${slug}` ,)
    return data
}

const postBank = async ({name}:any) => {
    return await axios.post('/admin/banks', {
        name
    })
}

const editBank = async ({ values, slug }: any) => {
    return await axios.put(`/admin/banks/${slug}` , {
        name : values.name
    })
}

const deleteBank = async (slug:string) => {
    return await axios.delete(`/admin/banks/${slug}`)
}

const updateStatus = async({status , slug}:any) => {
    const response = await axios.patch(`/admin/banks/${slug}` , {
        status : Number(status)
    })
    return response
}

export const bankServices = {
    getBanks,
    getBank,
    postBank,
    editBank,
    deleteBank,
    updateStatus,
    getPublicBanks
}