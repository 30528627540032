import {ErrorMessage, Field, useFormikContext} from 'formik'
import moment from 'moment'
import {Card, Col, Row} from 'react-bootstrap-v5'
import Select from 'react-select'

import {FallbackView} from '../../../../_metronic/partials'
import {useSupplierCampaigns} from '../../../hooks/useSupplierCampaigns'

const CouponForm = (props: any) => {
  const {products} = props
  const {setFieldValue, values}: any = useFormikContext()
  const {data: campaigns, isLoading} = useSupplierCampaigns('coupons')

  if (isLoading) return <FallbackView />

  const handleHotelChange = (propertyId: any) => {
    const selectedHotel = products.find((hotel: any) => hotel.id === parseInt(propertyId))
    setFieldValue('property', propertyId)
    setFieldValue('rooms', selectedHotel.rooms)
    setFieldValue('couponType', 'rooms')
    setFieldValue(
      'couponableId',
      selectedHotel?.rooms?.map((item: any) => item.id)
    )
  }

  const handleRoomChange = (val: any) => {
    const rooms = val?.map((item: any) => item?.value)
    if(rooms.length === 0){
      return setFieldValue('couponableId', values?.rooms?.map((item: any) => item.id))
    }
    setFieldValue('couponableId', rooms)
  }

  const handlePackages = (val: any) => {
      setFieldValue('couponType', 'packages')
      const packages = val?.map((item: any) => item?.value)
      setFieldValue('couponableId', packages)
  }

  const handleIsGlobal = (value: any) => {
    if (value === 'all') {
      setFieldValue('couponableId', [0])
      setFieldValue('couponType', 'users')
    }
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <div className='form-group d-flex flex-stack my-5'>
              <div className='d-flex flex-column'>
                <label className='form-label required'>Choose the type</label>
                <div className='fs-7 fw-semibold text-muted'>Choose content type</div>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='isSupplierGlobal'
                    value='all'
                    onClick={() => handleIsGlobal('all')}
                  />
                  <label
                    className='form-check-label text-gray-700 fw-bold text-nowrap'
                    htmlFor='kt_builder_header_content_menu'
                  >
                    For all products
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    value='selected'
                    name='isSupplierGlobal'
                    onClick={() => handleIsGlobal('selected')}
                  />
                  <label
                    className='form-check-label text-gray-700 fw-bold text-nowrap'
                    htmlFor='kt_builder_header_content_page-title'
                  >
                    For selective product
                  </label>
                </div>
              </div>
            </div>
            {values.isSupplierGlobal === 'selected' && (
              <>
                {values.userType === 'packages' ? (
                  <div className='mb-10'>
                    <label className='form-label'>Choose your package name</label>
                    <Field name='couponableId' required>
                      {({form, meta}: any) => {
                        const {setFieldValue} = form
                        const {value} = meta
                        const options = products?.map((item: any) => ({
                          label: item.title,
                          value: item.id,
                        }))
                        const defaultOptions = options?.filter((option: any) =>
                          value?.includes(option.value)
                        )
                        return (
                          <Select
                            defaultValue={defaultOptions}
                            isMulti
                            name='couponableId'
                            options={options}
                            className='basic-multi-select'
                            onChange={(val: any) => 
                              handlePackages(val)
                            }
                          />
                        )
                      }}
                    </Field>
                  </div>
                ) : (
                  <>
                    <div className='mb-10'>
                      <label className='form-label'>Choose your hotel name</label>
                      <Field
                        as='select'
                        name='property'
                        className='form-select'
                        required
                        onChange={(e: any) => handleHotelChange(e.target.value)}
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {products.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item?.title}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <label className='form-label'>Choose your rooms name</label>
                      <Field name='rooms' required>
                        {({form, meta}: any) => {
                          const {setFieldValue} = form
                          const {value} = meta
                          const options = value?.map((item: any) => ({
                            label: item.title,
                            value: item.id,
                          }))
                          const defaultOptions = options?.filter((option: any) =>
                            values.couponableId?.includes(option.value)
                          )
                          console.log({defaultOptions}, values.couponableId)
                          return (
                            <Select
                              defaultValue={defaultOptions}
                              isMulti
                              name='couponableId'
                              options={options}
                              className='basic-multi-select'
                              onChange={(val:any) => handleRoomChange(val)}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </>
                )}
              </>
            )}
            {/* )} */}
            {/* <div className='mb-10'>
                <label className='form-label'>Select hotels </label>
                <div
                  role='group'
                  aria-labelledby='my-radio-group'
                  // onChange={(e) => handleChange(e)}
                >
                  {props.userType === 'packages' ? (
                    <>
                      <input
                        type='radio'
                        name='userType'
                        value='packages'
                        onClick={() => props.setUserType('packages')}
                        checked={props.userType === 'packages'}
                      />
                      <label className='form-label mx-2'>All hotels </label>
                    </>
                  ) : (
                    <>
                      <label
                        // onClick={() => props.setUserType('properties')}
                        className='form-check-label'
                      >
                        <input
                          type='radio'
                          name='userType'
                          value='properties'
                          className='form-check-input mx-2'
                          onClick={handleProperty}
                          checked={props.userType === 'properties'}
                        />
                        Properties
                      </label>
                      <label
                        // onClick={() => props.setUserType('rooms')}
                        className='form-check-label'
                      >
                        <input
                          type='radio'
                          name='userType'
                          value='rooms'
                          className='form-check-input mx-2'
                          onClick={handleRoom}
                          checked={props.userType === 'rooms'}
                        />
                        Rooms
                      </label>
                    </>
                  )}
                </div>
              </div> */}
            {/* <div className='mb-10'>
                <label className='form-label'>Product Type</label>
                <div
                  role='group'
                  aria-labelledby='my-radio-group'
                >
                  {props.userType === 'packages' ? (
                    <>
                      <input
                        type='radio'
                        name='userType'
                        value='packages'
                        onClick={() => props.setUserType('packages')}
                        checked={props.userType === 'packages'}
                      />
                      <label className='form-label mx-2'>Packages</label>
                    </>
                  ) : (
                    <>
                      <label
                        // onClick={() => props.setUserType('properties')}
                        className='form-check-label'
                      >
                        <input
                          type='radio'
                          name='userType'
                          value='properties'
                          className='form-check-input mx-2'
                          onClick={handleProperty}
                          checked={props.userType === 'properties'}
                        />
                        Properties
                      </label>
                      <label
                        // onClick={() => props.setUserType('rooms')}
                        className='form-check-label'
                      >
                        <input
                          type='radio'
                          name='userType'
                          value='rooms'
                          className='form-check-input mx-2'
                          onClick={handleRoom}
                          checked={props.userType === 'rooms'}
                        />
                        Rooms
                      </label>
                    </>
                  )}
                </div>
              </div> */}
            <div className='mb-10'>
              <label className='form-label required'>Coupon Name</label>
              <Field
                type='text'
                className='form-control'
                placeholder='Coupon name'
                name='name'
                required
              />
              <div className='text-danger'>
                <ErrorMessage name='name' />
              </div>
            </div>
            <Row>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Coupon code</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder='Coupon Code'
                    name='couponCode'
                    required
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='couponCode' />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Discount Type</label>
                  <Field as='select' name='discountType' className='form-select' required>
                    <>
                      <option value='fixed'>Fixed</option>
                      <option value='percentage'>Percentage</option>
                    </>
                  </Field>
                  <div className='text-danger'>
                    <ErrorMessage name='discountType' />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                {values.discountType === 'fixed' ? (
                  <div className='mb-10'>
                    <label className='form-label required'>Discount value</label>
                    <Field
                      type='number'
                      className='form-control'
                      placeholder='Discount value'
                      name='discountValue'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='discountValue' />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='mb-10'>
                      <label className='form-label required'>Discount percent</label>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Discount percent'
                        name='discountPercent'
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='discountPercent' />
                      </div>
                    </div>
                    <div className='mb-10'>
                      <label className='form-label required'>Max Discount Cap</label>
                      <Field
                        className='form-control'
                        placeholder='Max Discount Cap'
                        name='maxDiscountCap'
                        type='number'
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='maxDiscountCap' />
                      </div>
                    </div>
                  </>
                )}
              </Col>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Minimum Spend</label>
                  <Field
                    type='number'
                    className='form-control'
                    placeholder='Minimum Spend'
                    name='minimumSpend'
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='minimumSpend' />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Coupon Usage Limit</label>
                  <Field
                    type='number'
                    className='form-control'
                    placeholder='coupon Usage Limit'
                    name='couponUsageLimit'
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='couponUsageLimit' />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Is Guest Allowed ?</label>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <Field className='form-check-input' type='checkbox' name='isGuestAllowed' />
                  </div>
                  <div className='text-danger'>
                    <ErrorMessage name='isGuestAllowed' />
                  </div>
                </div>
              </Col>
              {!values.isGuestAllowed && (
                <div className='mb-10'>
                  <label className='form-label required'>Per User Limit</label>
                  <Field
                    type='number'
                    className='form-control'
                    placeholder='Per User Limit'
                    name='perUserLimit'
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='perUserLimit' />
                  </div>
                </div>
              )}
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Actives At</label>
                  <Field
                    type='date'
                    className='form-control'
                    name='activesAt'
                    min={moment(new Date()).format('YYYY-MM-DD')}
                    required
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='activesAt' />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='mb-10'>
                  <label className='form-label required'>Expires At</label>
                  <Field
                    type='date'
                    className='form-control'
                    name='expiresAt'
                    min={moment(values.activesAt).add(1, 'days').format('YYYY-MM-DD')}
                    required
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='expiresAt' />
                  </div>
                </div>
              </Col>
            </Row>
            <div className='fv-row mb-10'>
              <label className='form-label'>Campaign</label>
              <Field as='select' name='campaignCode' className='form-select'>
                <option value=''>Choose your options</option>
                {campaigns?.map((campaign: any) => {
                  return (
                    <option key={campaign.id} value={campaign.code}>
                      {campaign.name}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='campaignCode' />
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label'>Remarks</label>
              <Field type='text' className='form-control' placeholder='Remarks' name='remarks' required={values.discountPercent == 100} />
              <div className='text-danger mt-2'>
                {/* <ErrorMessage name='remarks' /> */}
              </div>
            </div>
          </Col>
          <Col md={4} className='offset-md-1'>
            {/* <ProductsList
                values={values}
                userType={props.userType}
                setUserType={props.setUserType}
              /> */}
            {/* <label className='form-label mb-5'>Select your products</label>
              {data?.map((product: any, index: number) => (
                <div
                  className='form-check form-check-custom form-check-solid mb-5'
                  key={product.id}
                >
                  <label className='form-check-label'>
                    <Field
                      type='checkbox'
                      name='couponableId'
                      value={product.id}
                      className='form-check-input px=3'
                      key={product.id}
                      checked={values?.values?.couponableId?.includes(`${product.id}`)}
                    />
                    {product.title}
                  </label>
                </div>
              ))} */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default CouponForm
