import axios from "../../../../setup/axios/SetupAxios"
import axiosReq from "axios"

const impersonateUserLogin = async (id: number) => {
    const { data } = await axios.get(`/admin/users/${id}/login`)
    return data
}

const impersonateSupplierRequest = async (id: number) => {
    const { data } = await axios.get(`/admin/suppliers/${id}/login`)
    return data
}

const impersonateSupplierLogin = async (token:any) => {
    const {data} =  await axiosReq.get(`${process.env.REACT_APP_API_URL}/supplier/impersonate`, {
        headers: { 'Authorization': `Bearer ${token}` }
    })
    return data
};

export const impersonateServices = {
    impersonateUserLogin,
    impersonateSupplierRequest,
    impersonateSupplierLogin
}