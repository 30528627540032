import axios from '../../../setup/axios/SetupAxios'
import { PaymentMethod } from '../../_helpers/shared/models'

/*   ********************************
      Payment method supplier api starts
     *********************************
*/

const postPaymentMethod = (value: PaymentMethod) => {
  const {method, methodName, accountBranch, accountNumber, primary, accountName} = value
  const response = axios.post(`/supplier/payments/methods`, {
    method,
    method_name: methodName,
    account_branch: accountBranch,
    account_number: accountNumber.toString(),
    account_name: accountName,
    primary: !!primary,
  })
  return response
}

const getPaymentMethods = async (page: number) => {
  const {data}: any = await axios.get(`/supplier/payments/methods?page=${page}`)
  return data
}

const getPaymentMethod = async (id: number) => {
  const {data} = await axios.get(`/supplier/payments/methods/${id}`)
  return data
}

const deletePaymentMethod = async (id: number) => {
  try {
    const data = await axios({
      method: 'DELETE',
      url: `/supplier/payments/methods/${id}`,
    })
    return data
  } catch (error) {}
}

const editPaymentMethod = async ({value , id}: any) => {
  const {method, methodName, accountBranch, accountNumber, primary, accountName} = value
  const response = await axios.put(`/supplier/payments/methods/${id}`, {
    method,
    method_name: methodName,
    account_branch: accountBranch,
    account_number: accountNumber,
    account_name: accountName,
    primary: !!primary,
  })
  return response
}

/*   ********************************
      Payment method supplier api ends
     *********************************
*/

/*   ********************************
      Payment requests supplier api starts
     *********************************
*/

const getPaymentRequests = async (page: number , status:string) => {
  const {data} = await axios.get(`/supplier/payments/requests?status=${status}&page=${page}`)
  return data
}

const getPaymentRequest = async (id: number) => {
  const {data} = await axios.get(`/supplier/payments/requests/${id}`)
  return data
}

const postPaymentRequests = async (value: any) => {
  const {payment_method, request_amount} = value
  const response = await axios.post(`/supplier/payments/requests`, {
    payment_method,
    request_amount,
  })
  return response
}

const editPaymentRequests = async ({value, id}: any) => {
  const {payment_method, request_amount} = value
  const response = await axios.put(`/supplier/payments/requests/${id}`, {
    payment_method: Number(payment_method),
    request_amount,
  })
  return response
}

const deletePaymentRequests = async (id: number) => {
  try {
    const data = await axios({
      method: 'DELETE',
      url: `/supplier/payments/requests/${id}`,
    })
    return data
  } catch (error) {}
}


/*   ********************************
      Payment requests supplier api ends
     *********************************
*/

export const paymentServices = {
  postPaymentMethod,
  getPaymentMethods,
  getPaymentMethod,
  deletePaymentMethod,
  editPaymentMethod,
  getPaymentRequests,
  postPaymentRequests,
  deletePaymentRequests,
  editPaymentRequests,
  getPaymentRequest,
}
