import React from 'react'
import { Formik, Form } from 'formik'
import { useMutation, useQueryClient } from 'react-query'

import { PageTitle } from '../../../_metronic/layout/core'
import { paymentServices } from '../../services/api/payment'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import PaymentRequestForm from './PaymentRequestForm'
import { useUserRole } from '../../hooks/useUserRole'
import { ROLE } from '../../_helpers/Role'
import { FallbackView } from '../../../_metronic/partials'
import { Error500 } from '../../modules/errors/components/Error500'
import { paymentMethodSchema } from './paymentMethodSchema'
import { usePayementRequest } from '../../hooks/usePaymentRequest'
import { useGetCompany } from '../../hooks/useGetCompany'

const profileBreadCrumbs = [
    {
        title: 'Payment Requests',
        path: '/payment-request',
        isSeparator: false,
        isActive: false,
    },
]

const AddEditPayment = () => {

    useUserRole([ROLE.HotelSupplier, ROLE.TravelSupplier]);

    const history = useHistory();

    const queryClient = useQueryClient();

    const params: any = useParams();

    const id: number = Number(params?.id);

    const isAddMode = !id;

    const { data, isLoading, isError } = usePayementRequest(id);

    const { data: supplier, isLoading: isCompanyFetching } = useGetCompany();

    const mutation: any = useMutation(paymentServices.postPaymentRequests, {
        onSuccess: () => {
            queryClient.invalidateQueries('payment-requests')
        },
        onError: (error: any) => {
            toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
        },
    });

    const editMutation: any = useMutation(paymentServices.editPaymentRequests, {
        onSuccess: () => {
            queryClient.invalidateQueries('payment-requests')
        },
        onError: (error: any) => {
            toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
        },
    });

    const onFormSubmit = (value: any) => {
        isAddMode
            ? mutation.mutate(value, {
                onSuccess: (data: any) => {
                    toast.success('Payment request added successfully')
                    history.push(`/payment-request`)
                },
                onError: (error: any) => {
                    toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
                },
            })
            : editMutation.mutate({ value, id },
                {
                    onSuccess: (data: any) => {
                        toast.success('Payment request updated successfully')
                        history.push(`/payment-request`)
                    },
                    onError: (error: any) => {
                        toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
                    },
                }
            )
    };

    if (isLoading || isCompanyFetching) return <FallbackView />;

    if (isError) return <Error500 />;

    const initValues = {
        payment_method: data?.payment_method_id || "",
        request_amount: isAddMode ? supplier.payout.pending_accumulated_amount : data.request_amount,
    };

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Payment Requests</PageTitle>
            <div className='card card-custom'>
                <div className='card-header'>
                    <h3 className='card-title'>Add Payment Requests</h3>
                    <div className='card-toolbar'>
                    </div>
                </div>
                <div className='card-body py-5'>
                    <Formik
                        validationSchema={paymentMethodSchema}
                        initialValues={initValues}
                        onSubmit={onFormSubmit}
                    >
                        {() => (
                            <Form>
                                <PaymentRequestForm pendingAccumulatedAmount={supplier.payout.pending_accumulated_amount} />
                                <button
                                    type='submit'
                                    id='kt_sign_in_submit'
                                    className='btn btn-lg btn-primary w-10 my-5'
                                    disabled={editMutation.isLoading || mutation.isLoading}
                                >
                                    {editMutation.isLoading || mutation.isLoading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : "Submit"}
                                </button>
                            </Form>
                        )}
                </Formik>
            </div>
        </div>
        </>
    )
}

export default AddEditPayment
