export const statusCheck = (status: any) => {
  switch (status?.toLowerCase()) {
    case 'pending':
      return <span className='badge badge-light-warning'>Pending</span>
      case 'payment_pending':
      return <span className='badge badge-light-warning'>Payment Pending</span>
      case 'payment_verification_pending':
        return <span className='badge badge-light-warning'>Payment Verification Pending</span>;
        case 'payment_failed':
          return <span className='badge badge-light-warning'>Payment Failed</span>;
      case 'payment_verification_failed':
      return <span className='badge badge-light-danger'>Payment Verification Failed</span>
      case 'update_on_review':
        return <span className='badge badge-light-warning'>Update On Review</span>
    case 'approved':
      return <span className='badge badge-light-success'>Approved</span>
    case 'cancelled':
      return <span className='badge badge-light-danger'>Cancelled</span>
      case 'cancelled_by_supplier':
        return <span className='badge badge-light-danger'>Cancelled By Supplier</span>
    case 'refunded':
      return <span className='badge badge-light-success'>Refunded</span>
    case 'cancellation_approved':
      return <span className='badge badge-light-success'>Cancellation Approved</span>
    case 'cancellation_rejected':
      return <span className='badge badge-light-danger'>Cancellation Reject</span>
    case 'cancellation_pending':
      return <span className='badge badge-light-warning'>Cancellation Pending</span>
    case 'rejected':
      return <span className='badge badge-light-danger'>Rejected</span>
    case 'verified':
      return <span className='badge badge-light-success'>Verified</span>
    case 'completed':
      return <span className='badge badge-light-success'>Completed</span>
      case 'suspended':
        return <span className='badge badge-light-warning'>Suspended</span>
    case 'fraud_suspected':
      return <span className='badge badge-light-danger'>Fraud Suspected</span>
    case 'deactivated_for_fraud':
      return <span className='badge badge-light-danger'>Deactivated</span>
    case 'deleted':
      return <span className='badge badge-light-danger'>Deleted</span>
    case 'restored':
      return <span className='badge badge-light-primary'>Restored</span>
    case 'verify_updated':
      return <span className='badge badge-light-primary'>Verify updated</span>
    case 'reject_update':
      return <span className='badge badge-light-primary'>Reject updated</span>
    case 'approve_updated':
      return <span className='badge badge-light-success'>Approve Updated</span>
    case 'reject_updated':
      return <span className='badge badge-light-danger'>Reject Updated</span>
    default:
      return <span className='badge badge-light-primary'>{status}</span>
  }
}
