import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { FC } from 'react'

import * as Yup from 'yup'
import { useChangePassword } from '../../../../hooks/useChangePassword'

export interface password {
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
};

export const changePasswordSchema = Yup.object({
    currentPassword: Yup.string()
        .min(8, 'Must be 8 characters')
        .required('Current password is required'),
    newPassword: Yup.string()
        .min(8, 'Must be 8 characters')
        // .matches(regression.passwordRegx , "Password must be at least 8 characters long including at least one Uppercase, one lowercase, one Number and one Special Character")
        .required('New password required'),
    newPasswordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Password is required")
});

const initialValues = { currentPassword: '', newPassword: '', newPasswordConfirmation: '' };

const ChangePassword: FC = () => {
    const mutation = useChangePassword();
    const onFormSubmit = (values: password) => {
        mutation.mutate(values)
    };

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Change Password</h3>
                </div>
            </div>

            <div className="card-body">
                <Formik validationSchema={changePasswordSchema} initialValues={initialValues} onSubmit={onFormSubmit}>
                    {() => (
                        <Form className='w-100 pb-10'>
                            <div className='row mb-1'>
                                <div className='col-lg-4'>
                                    <div className='fv-row mb-0'>
                                        <label htmlFor='currentPassword' className='form-label fs-6 fw-bolder mb-3'>
                                            Current Password
                                        </label>
                                        <Field
                                            type='password'
                                            className='form-control form-control-lg form-control-solid '
                                            id='currentpassword'
                                            name='currentPassword'
                                        />
                                        <ErrorMessage component="div" className='text-danger' name='currentPassword' />
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='fv-row mb-0'>
                                        <label htmlFor='newPassword' className='form-label fs-6 fw-bolder mb-3'>
                                            New Password
                                        </label>
                                        <Field
                                            type='password'
                                            className='form-control form-control-lg form-control-solid '
                                            id='newPassword'
                                            name='newPassword'
                                        />
                                        <ErrorMessage component="div" className='text-danger' name='newPassword' />
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='fv-row mb-0'>
                                        <label htmlFor='newPasswordConfirmation' className='form-label fs-6 fw-bolder mb-3'>
                                            Confirm New Password
                                        </label>
                                        <Field
                                            type='password'
                                            className='form-control form-control-lg form-control-solid '
                                            id='newPasswordConfirmation'
                                            name='newPasswordConfirmation'
                                        />
                                        <ErrorMessage component="div" className='text-danger' name='newPasswordConfirmation' />
                                    </div>
                                </div>
                            </div>

                            <div className='form-text mb-5'>
                                Password must be at least 8 character and contain symbols
                            </div>

                            <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-lg btn-primary w-10 m-5 text-center'
                            disabled={mutation.isLoading}
                        >
                            {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                            {mutation.isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ChangePassword