
export function FallbackView() {
  return (
    <div className='d-flex justify-content-center align-items-center vh-40'>
      <div className='spinner-grow' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}
