import React from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useAuth} from '../../hooks/useAuth'

export function Logout() {
  const history = useHistory()
  const {setAuth} = useAuth()
  React.useEffect(() => {
    localStorage.removeItem('session')
    setAuth('')
    history.push('/auth/login')
    document.location.reload()
  })
  return (
    <Switch>
      <Redirect to='/auth/login' />
      <Redirect to='/auth/registration' />
    </Switch>
  )
}
