import React from 'react'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'
import { usePaymentMethods } from '../../hooks/usePaymentMethods'
import {useUserRole} from '../../hooks/useUserRole'
import {ErrorsPage} from '../../modules/errors/ErrorsPage'
import {ROLE} from '../../_helpers/Role'
import {LoadingView} from '../LoadingView'
import PaymentMethodItem from './PaymentMethodItem'
import Pagination from '../../components/Pagination'

const paymentBreadCrumbs = [
  {
    title: 'Payment',
    path: '/payment-method',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentMethod = () => {
  useUserRole([ROLE.HotelSupplier, ROLE.TravelSupplier]);
  const [page, setPage] = React.useState<number>(1);
  const {
    data: paymentMethods,
    isLoading,
    isError,
  } = usePaymentMethods(page);

  if (isLoading) {
    return <LoadingView />
  };
  if (isError) {
    return <ErrorsPage />
  };

  return (
    <>
      <PageTitle breadcrumbs={paymentBreadCrumbs}>Payment Methods</PageTitle>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Payment Methods</span>
            <span className='text-muted mt-1 fw-bold fs-7'>All Payment Methods</span>
          </h3>

          {/* <!--begin::Trigger--> */}
          {/* <CDropdown items={items} /> */}
          {/* <!--end::Menu--> */}

          <div className='card-toolbar'>
            <Link to='/payment-method/add'>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                Add Payment Method
                {/* <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' /> */}
              </button>
            </Link>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            ></div>
          </div>
        </div>
        {paymentMethods?.data?.length > 0 ? (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='w-25px text-captalise'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-13-check'
                        />
                      </div>
                    </th>
                    <th className='min-w-120px'>Id</th>
                    <th className='min-w-120px'>Method Name</th>
                    <th className='min-w-120px'>Account Placeholder Name</th>
                    <th className='min-w-120px'>Account Number</th>
                    <th className='min-w-120px'>Account Branch</th>
                    <th className='min-w-140px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods?.data?.map((item: any, index: any) => (
                    <PaymentMethodItem index={index} key={item?.id} item={item} page={page} />
                  ))}
                </tbody>
              </table>
            </div>
            {/* pagination */}
            <Pagination page={page} setPage={setPage} lastPage={paymentMethods.last_page} />
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <h4>No payment method to show</h4>
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentMethod
