import  { Route, Switch} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'
import AddEditCoupon from './components/AddEditCoupon'
import CouponList from './components/CouponList'

const couponBreadCrumbs = [
  {
    title: 'Coupon',
    path: '/coupons',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CouponMainPage = () => {
  return (
    <Switch>
      <Route exact path='/offers/coupon'>
        <PageTitle breadcrumbs={couponBreadCrumbs}>All Coupons</PageTitle>
        <CouponList />
      </Route>
      <Route exact path='/offers/coupon/add'>
        <PageTitle breadcrumbs={couponBreadCrumbs}>Add Coupon</PageTitle>
        {/* <AddCoupon /> */}
        <AddEditCoupon />
      </Route>
      <Route exact path='/offers/coupon/:id/edit'>
        <PageTitle breadcrumbs={couponBreadCrumbs}>Edit Coupon</PageTitle>
        {/* <EditCoupon /> */}
        <AddEditCoupon />
      </Route>
    </Switch>
  )
}

export default CouponMainPage
