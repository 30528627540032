import moment from 'moment'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {formatDateTime} from '../../../utils/formatDate' 
import { FallbackView } from '../../../../_metronic/partials'
import { useUserRole } from '../../../hooks/useUserRole'
import { Error500 } from '../../../modules/errors/components/Error500'
import { bookingServices } from '../../../services/api/booking'
import { categoryType } from '../../../utils/categoryType'
import BookingActions from '../../../_helpers/BookingActions'
import { ROLE } from '../../../_helpers/Role'
import { statusCheck } from '../../../_helpers/statusCheck'

const Booking = () => {
  useUserRole([ROLE.TravelSupplier, ROLE.HotelSupplier])
  const history = useHistory()
  const queryClient = useQueryClient()
  const { bookingReference }: any = useParams()
  const [show, setShow] = useState(false)
  const [options, setOptions]: any = useState('choose')
  const { data, isLoading, isError } = useQuery(['booking', bookingReference], () => bookingServices.getBooking(bookingReference))
  const mutation = useMutation(bookingServices.supplierBookingAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['booking', bookingReference])
      toast.success('Booking status updated successfully')
      setShow(false)
      history.push('/bookings')
    },
    onError: () => {
      setShow(false)
      toast.error('Please try again later')
    },
  })

  if (isLoading) {
    return <FallbackView />
  }
  if (isError) {
    return <Error500 />
  }

  const handleClick = (e: any) => {
    setOptions(e.target.value)
    setShow(true)
  }
  const handleClose = () => {
    setOptions('choose')
    setShow(false)
  }
  const checkStatus = data?.status

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='horizontal-card card'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{data.product?.property_name || data.product?.title}</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row no-gutters'>
            <div className='col-md-4'>
              <img
                className='bd-placeholder-img'
                width='100%'
                height='100%'
                src={data?.product?.thumbnail?.url}
                alt={data?.product?.thumbnail?.url}
                style={{ aspectRatio: '16 / 9', objectFit: 'cover' }}
              />
            </div>
            <div className='col-md-8'>
              <div className='card-body p-3'>
                <div className='card-title text-left'>
                  <span className='badge badge-primary text-capitalize mb-3'>
                    {data?.product?.category}
                  </span>
                  <h4 className='mb-0 text-capitalize font-weight-bold'>{data?.product?.title}</h4>
                  {/* <div className='d-flex justify-content-between align-items-center my-2'>
                    <span>{data?.product?.location}</span>
                  </div> */}
                </div>
                <div className='card-description text-left'>
                  {data?.product?.description?.slice(0, 250)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            Booking Details
            {statusCheck(checkStatus)}
          </h3>
        </div>
        <BookingActions
          show={show}
          setShow={setShow}
          mutation={mutation}
          handleClick={handleClick}
          handleClose={handleClose}
          options={options}
          checkStatus={checkStatus}
          bookingReference={bookingReference}
          data={data}
        />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Booking Reference: </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.booking_reference}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Lead Traveler Name: </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.lead_traveller_name}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Lead Traveler Email: </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.lead_traveller_email}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Lead Traveler Phone: </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.lead_traveller_phone}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Additional Guests : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  <ul className='px-0' style={{ listStyleType: "disc" }}>
                    {data?.additional_traveller_details?.length > 0
                      ? data?.additional_traveller_details?.map((item: any) => (
                        <li key={item}>{item?.name}</li>
                      ))
                      : '-'}
                  </ul>
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                {`${categoryType(data?.product_type) === 'room' ? 'Check-in date: ' : 'Start Date: '
                  }`}{' '}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {moment(data?.start_date_time || data?.starts_at).format('YYYY/MM/DD')}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                {`${categoryType(data?.product_type) === 'room' ? 'Check-out date: ' : 'End Date: '
                  }`}{' '}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {moment(data?.end_date_time || data?.ends_at).format('YYYY/MM/DD')}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Contact Details: </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {data?.lead_traveller_phone || '-'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Price : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>Rs. {data?.price}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Currency : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.currency}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                {' '}
                {`${categoryType(data?.product_type) === 'room' ? ' Number of rooms: ' : 'Quantity: '
                  }`}{' '}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.quantity}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Total Price : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>Rs. {data?.total_amount}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Total paid : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>Rs. {data?.total_paid}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Booked Date : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{formatDateTime(data?.created_at)}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Note : </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.note || "-"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Booking
