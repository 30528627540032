import moment from "moment"

export const formatDate = (date:any) => {
    return moment(new Date(date)).format('YYYY-MM-DD')
}

export function formatDateTime(date:any) {
  return moment(new Date(date)).format('yyyy MMM DD / HH:mm');
}

export function formatExpiryDateTime(date:any) {
  return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
}