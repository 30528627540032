import React from 'react'

type Props = {
    page: number,
    lastPage: number,
    setPage: Function
}
const Pagination = (props: Props) => {
    return (
        <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>
                Showing results {props.page} out of {props.lastPage} pages{' '}
            </div>
            <div className='fs-6 fw-bold text-gray-700'></div>
            <ul className='pagination'>
                <li className='page-item previous'>
                    <button
                        className='page-link'
                        onClick={() => props.setPage((page: number) => page - 1)}
                        disabled={props.page === 1}
                    >
                        <i className='previous'></i>
                    </button>
                </li>
                <li className='page-item active'>
                    <button className='page-link'>{props.page}</button>
                </li>
                <li className='page-item next'>
                    <button
                        className='page-link'
                        onClick={() => props.setPage((page: number) => page + 1)}
                        disabled={props.page === props.lastPage}
                    >
                        <i className='next'></i>
                    </button>
                </li>
            </ul>
        </div>)
}

export default Pagination