import React from 'react'
import {ErrorMessage, Field} from 'formik'
import {useQuery} from 'react-query'
import {ErrorsPage} from '../../modules/errors/ErrorsPage'
import {paymentServices} from '../../services/api/payment'
import {LoadingView} from '../LoadingView'

const PaymentRequestForm = (props:any) => {
  
  const {
    data: paymentMethods,
    isLoading,
    isError,
  }: any = useQuery(['payments'], () => paymentServices.getPaymentMethods(1))

  if (isLoading ) {
    return <LoadingView />
  }

  if (isError) {
    return <ErrorsPage />
  }

  return (
    <>
      <div className='mb-10'>
        <label className='form-label'>Payment Method</label>
        <Field as='select' name='payment_method' className='form-select' required>
          <>
            <option value='' disabled>
              Choose
            </option>
            {paymentMethods?.data?.map((item: any) => (
              <option key={item?.id} value={item?.id}>{`${item?.method_name}`} {item.primary && "✓"}</option>
            ))}
          </>
        </Field>
        <div className='text-danger'>
          <ErrorMessage name='payment_method' />
        </div>
      </div>
      <div className='mb-10'>
        <label className='form-label'>Request Amount</label>
        <Field
          type='number'
          className='form-control'
          placeholder='Enter amount'
          name='request_amount'
        />
        <span>Max request amount : Rs. {props.pendingAccumulatedAmount}</span>
        <div className='text-danger'>
          <ErrorMessage name='request_amount' />
        </div>
      </div>
    </>
  )
}

export default PaymentRequestForm
