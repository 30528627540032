import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { paymentServices } from "../services/api/payment"

export const useDeletePaymentRequests = (handleClose:Function) => {

    const queryClient = useQueryClient();

    return useMutation(paymentServices.deletePaymentRequests , {
        onSuccess: (data: any) => {
            toast.success('Payment requests deleted successfully')
            queryClient.invalidateQueries('payment-requests')
            handleClose()
          },
    })
}