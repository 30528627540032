export const productOptions = [
  {
    value: 'Pending',
  },
  {
    value: 'Verified',
  },
  {
    value: 'Deleted',
  },
  {
    value: 'Restored',
  },
  {
    value: 'Rejected',
  },
]

export const offerOptions = [
  {
    value: 'active',
  },
  {
    value: 'inactive',
  },
]
