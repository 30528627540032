import React from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import Bookings from './components/Bookings'
import Booking from './components/Booking'

const bookingsBreadCrumbs = [
  {
    title: 'Booking',
    path: '/bookings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const BookingsPage = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match?.url}`}>
        <PageTitle breadcrumbs={bookingsBreadCrumbs}>All Bookings</PageTitle>
        <Bookings className='' />
      </Route>
      <Route path={`${match?.url}/:bookingReference/view`}>
        <PageTitle breadcrumbs={bookingsBreadCrumbs}>Booking Details</PageTitle>
        <Booking />
      </Route>
      <Redirect from='/bookings' exact={true} to='/bookings' />
      <Redirect to='/bookings' />
    </Switch>
  )
}

export default BookingsPage
