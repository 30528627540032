/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {KTSVG} from '../../../../_metronic/helpers'
import {PRODUCT_TYPE} from '../../../constants/product-type'
import {useProductType} from '../../../hooks/useProductType'
import {offerServices} from '../../../services/api/offerPrice'
import {formatDate, formatExpiryDateTime} from '../../../utils/formatDate'
import ModalView from '../../modals/DeleteItem'

const OfferPriceItem = ({item, index}: any) => {
  const type = useProductType()
  const queryClient = useQueryClient()
  const [show, setShow] = React.useState(false)
  const mutation = useMutation(offerServices.deleteOfferPrice, {
    onSuccess: (data: any) => {
      handleClose()
      queryClient.invalidateQueries('offers')
      toast.success('Offer price deleted successfully')
    },
    onError: (data: any) => {
      handleClose()
      queryClient.invalidateQueries('offers')
      toast.error('Error while deleting')
    },
  })

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const removeOffer = () => {
    mutation.mutate(item.id, {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries('offers')
      },
      onError: () => {
        handleClose()
        toast.error('Please try again')
      },
    })
  }

  return (
    <tr key={item.id}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-13-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
          {index + 1}
        </a>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'>
              {`${type === PRODUCT_TYPE.HOTEL ? `${item?.product?.property_name} /` : ''}`}{' '}
              {item.product.title}
              <span
                className={`badge badge-light-${
                  new Date(item.expires_at) < new Date() && 'danger'
                } fs-9 fw-bolder m-2`}
              >
                {new Date(item.expires_at) < new Date() && 'expired'}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'>
              {`Rs. ${item?.product.unit_price || item?.product.room_rate}`}
            </span>
          </div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark text-capitalize fw-bolder text-hover-primary fs-6'>
              {`Rs. ${item.offer_price}`}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span
          className={`badge badge-light-${
            !item?.is_active ? 'warning' : 'success'
          } fs-9 fw-bolder m-2`}
        >
          {item.is_active ? 'active' : 'inactive'}
        </span>
      </td>
      <td className='text-dark fw-bolder text-hover-primary fs-6'>
        {formatExpiryDateTime(item.actives_at)}
      </td>
      <td className='text-dark fw-bolder text-hover-primary fs-6'>
        {formatExpiryDateTime(item.expires_at)}
      </td>
      <td>
        <Link
          to={`/offers/offer-price/${item.id}/edit`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
        <a
          onClick={handleShow}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
        <ModalView
          show={show}
          isLoading={mutation.isLoading}
          remove={removeOffer}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </td>
    </tr>
  )
}

export default OfferPriceItem
