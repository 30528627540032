import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { editCompany } from "../services/api/company";

export const useEditCompany = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation(editCompany, {
        onSuccess: () => {
            queryClient.invalidateQueries(["company"])
            toast.success('Profile updated successfully')
        },
        onError: (error: any) => {
            toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
        },
    })
    return mutation
}