import React from 'react'
import { Formik, Form } from 'formik'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { PageTitle } from '../../../_metronic/layout/core'
import { paymentServices } from '../../services/api/payment'
import PaymentMethodForm from './PaymentMethodForm'

//types
import { PaymentMethod, PaymentMethodInitValues, PaymentMethodSchema } from '../../_helpers/shared/models'

const profileBreadCrumbs = [
  {
    title: 'Payment Method',
    path: '/payment-method',
    isSeparator: false,
    isActive: false,
  },
]

const AddPaymentMethod = () => {
  const history = useHistory()
  const mutation = useMutation(paymentServices.postPaymentMethod, {
    onSuccess: () => {
      toast.success('Payment method added successfully')
      history.push(`/payment-method`)
    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })

  const onFormSubmit = (value: PaymentMethod) => {
    mutation.mutate(value)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Payment Method</PageTitle>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Add Payment Method</h3>
          <div className='card-toolbar'>
          </div>
        </div>
        <div className='card-body py-5'>
          <Formik
            validationSchema={PaymentMethodSchema}
            initialValues={PaymentMethodInitValues}
            onSubmit={onFormSubmit}
          >
            {() => (
              <Form>
                <PaymentMethodForm />
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-10 mb-5'
                  disabled={mutation.isLoading}
                >
                  {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                  {mutation.isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default AddPaymentMethod
