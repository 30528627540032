import * as Yup from 'yup';

export interface PaymentMethod {
    method: 'bank' | 'wallet',
    methodName: string,
    accountBranch?: string,
    accountNumber: string | number,
    accountName: string,
    primary: boolean
};

export const PaymentMethodSchema = Yup.object().shape({
    method: Yup.string().required('Method is required'),
    methodName: Yup.string().max(255, 'Maximum 255 characters').required('Method Name is required'),
    accountBranch: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(255, 'Maximum 255 characters')
        .nullable(),
    // .required('Account Branch Name is required'),
    accountNumber: Yup.string()
        .min(5, 'Account Number must be at least 5 characters')
        .max(50, 'Account Number must be at most 255 characters')
        .required('Account Number is required'),
    accountName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(255, 'Maximum 255 characters')
        .required('Account Name is required'),
});

export const PaymentMethodInitValues: PaymentMethod = {
    method: 'bank',
    methodName: '',
    accountBranch: '',
    accountNumber: '',
    accountName: '',
    primary: false
};

