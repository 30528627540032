export const getSession = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session || '')
  }
}
/**
 * setToken from localstorage
 */
export const setSessionInLocalStorage = (token: any) => {
  localStorage.setItem('session', JSON.stringify(token))

  return true
}
