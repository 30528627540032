import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { FallbackView } from '../../_metronic/partials'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import BookingsPage from '../pages/bookings/BookingsPage'
import { useAuth } from '../hooks/useAuth'
import { ROLE } from '../_helpers/Role'
import PaymentMethod from '../pages/paymentMethod/PaymentMethod'
import EditPaymentMethod from '../pages/paymentMethod/EditPaymentMethod'
import AddPaymentMethod from '../pages/paymentMethod/AddPaymentMethod'
import PaymentRequests from '../pages/paymentRequests/PaymentRequests'
import CouponMainPage from '../pages/coupons/CouponMainPage'
import OfferPriceMainPage from '../pages/offerPrice/OfferPriceMainPage'
import SupplierProfilePage from '../pages/supplierProfile/SupplierProfile'
import AddEditPayment from '../pages/paymentRequests/AddEditPayment'
import ViewPaymentRequest from '../pages/paymentRequests/ViewPaymentRequests'
import SupplierDashboard from '../pages/layout/SupplierDashboard'

export function PrivateRoutes() {

  const { session } = useAuth()

  const role = session?.user?.role

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../pages/dashboardTopbar/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const TravelPage = lazy(() => import('../pages/travel/TravelPage'))
  const HotelMainPage = lazy(() => import('../pages/hotel/HotelMainPage'))
  const AdminPage = lazy(() => import('../pages/admin/AdminPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/dashboard' component={SupplierDashboard} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path="/supplier/company" component={SupplierProfilePage} />
        <Route path='/bookings' component={BookingsPage} />
        <Route path='/package' component={TravelPage} />
        <Route path='/hotel' component={HotelMainPage} />
        <Route path='/admin' component={AdminPage} />
        <Route path='/offers/coupon' component={CouponMainPage} />
        <Route path='/offers/offer-price' component={OfferPriceMainPage} />
        <Route path='/payment-method/add' exact component={AddPaymentMethod} />
        <Route path='/payment-method' exact component={PaymentMethod} />
        <Route path={`/payment-method/edit/:id`} exact component={EditPaymentMethod} />
        <Route path='/payment-request/add' exact component={AddEditPayment} />
        <Route path='/payment-request' exact component={PaymentRequests} />
        <Route path='/payment-request/:id/view' exact component={ViewPaymentRequest} />
        <Route path={`/payment-request/edit/:id`} exact component={AddEditPayment} />
        {/* <Route path='/menu-test' component={MenuTestPage} /> */}
        {role === ROLE.Admin ? (
          <>
            <Redirect exact from='/dashboard' to='/admin/dashboard' />
            <Redirect exact from='/' to='/admin/dashboard' />
          </>
        ) : (
          <>
            <Redirect from='/auth' to='/dashboard' />
            <Redirect exact from='/' to='/dashboard' />
          </>
        )}
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
