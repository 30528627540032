import { useQuery } from 'react-query'

import { paymentServices } from '../services/api/payment'

const usePayementRequest = (id: number) => {
    return useQuery(["payment-request" , id], () => paymentServices.getPaymentRequest(id) , {
        enabled : !!id
    })
};

export { usePayementRequest };
