import React from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import moment from 'moment'
import {useHistory, useParams} from 'react-router-dom'
import {Formik, Form} from 'formik'

import {PageTitle} from '../../../../_metronic/layout/core'
import {FallbackView} from '../../../../_metronic/partials'
import {Error500} from '../../../modules/errors/components/Error500'
import {OfferSchema} from './OfferSchema'
import OfferPriceForm from './OfferPriceForm'
import {offerServices} from '../../../services/api/offerPrice'
import {useUser} from '../../../hooks/useUser'
import {useOffer} from '../../../hooks/useOffer'

const OfferPriceBreadCrumbs = [
  {
    title: 'Offer price',
    path: '/offer/offer-price',
    isSeparator: false,
    isActive: false,
  },
]

const AddEdit = () => {
  const user = useUser()
  const userType = user.type
  const queryClient = useQueryClient()
  const history = useHistory()
  const params: any = useParams()
  const id: number = Number(params?.id)
  const isAddMode = !id
  const {data, isLoading, isError} = useOffer(id)
  // const products = useQuery('offer-price-products', offerServices.getOfferPriceProducts);
  const mutation: any = useMutation(offerServices.editOfferPrice, {
    onSuccess: () => {
      toast.success('Offer price edited successfully')
      queryClient.invalidateQueries(['offer' , id])
    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })
  const addMutation: any = useMutation(offerServices.postOfferPrice, {
    onSuccess: () => {
      toast.success('Offers added successfully')
      queryClient.invalidateQueries('offers')
      history.push(`/offers/offer-price`)
    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })

  const onFormSubmit = (values: any) => {
    isAddMode
      ? addMutation.mutate({...values, userType})
      : mutation.mutate(
          {...values, id, userType},
          {
            onSuccess: (data: any) => {
              history.push(`/offers/offer-price`)
            },
          }
        )
  }

  if (isLoading) return <FallbackView />

  if (isError) return <Error500 />

  const initValues = {
    products: isAddMode ? '' : data?.product,
    offerPrice: data?.offer_price || '',
    productType: userType === 'Travel' ? 'packages' : 'rooms',
    property: isAddMode ? '' : Number(data?.product?.property_id),
    roomSlug: isAddMode ? '' : data?.product?.slug,
    packageSlug: isAddMode ? '' : data?.product.slug,
    activesAt: isAddMode ? '' : moment(data?.actives_at).format('YYYY-MM-DDTHH:mm:ss'),
    expiresAt: isAddMode ? '' : moment(data?.expires_at).format('YYYY-MM-DDTHH:mm:ss'),
    campaignCode: isAddMode ? '' : data?.campaigns[0]?.code,
  }

  return (
    <>
      <PageTitle breadcrumbs={OfferPriceBreadCrumbs}>
        {isAddMode ? 'Add Offer Price' : 'Edit Offer Price'}
      </PageTitle>
      <div className=''>
        <h6>Offer Price Information</h6>
        <Formik validationSchema={OfferSchema} initialValues={initValues} onSubmit={onFormSubmit}>
          {(props) => (
            <Form className='w-100 pb-10'>
              <OfferPriceForm userType={userType} />
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-10 my-5'
                disabled={addMutation.isLoading || mutation.isLoading}
              >
                {addMutation.isLoading || mutation.isLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Submit'
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default AddEdit
