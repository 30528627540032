import React from 'react'
import { Formik, Form } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { PageTitle } from '../../../_metronic/layout/core'
import { paymentServices } from '../../services/api/payment'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import PaymentMethodForm from './PaymentMethodForm'
import { FallbackView } from '../../../_metronic/partials'

//types
import { PaymentMethodSchema, PaymentMethod } from '../../_helpers/shared/models'
import { Error500 } from '../../modules/errors/components/Error500'

const profileBreadCrumbs = [
  {
    title: 'Payment Method',
    path: '/payment-method',
    isSeparator: false,
    isActive: false,
  },
]

const EditPaymentMethod = () => {
  const params: any = useParams()
  const id = Number(params?.id)
  const history = useHistory()
  const queryClient = useQueryClient();
  const { data, isLoading , isError } = useQuery(['payment', id], () => paymentServices.getPaymentMethod(id))
  const mutation: any = useMutation(paymentServices.editPaymentMethod, {
    onSuccess: () => {
      toast.success('Payment method added successfully')
      history.push(`/payment-method`)
      queryClient.invalidateQueries(['payment-methods'])

    },
    onError: (error: any) => {
      toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
    },
  })

  if (isLoading) return <FallbackView />;
  if (isError) return <Error500 />;

  const onFormSubmit = (value: PaymentMethod) => {
    mutation.mutate({ value, id })
  }
  const initValues: PaymentMethod = {
    method: data?.method.toLowerCase(),
    methodName: data?.method_name,
    accountBranch: data?.account_branch,
    accountNumber: data?.account_number,
    accountName: data?.account_name,
    primary: data?.primary,
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Payment Method</PageTitle>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Add Payment Method</h3>
          <div className='card-toolbar'>
            {/* <button type='button' className='btn btn-sm btn-light'>
            Action
          </button> */}
          </div>
        </div>
        <div className='card-body py-5'>
          <Formik
            validationSchema={PaymentMethodSchema}
            initialValues={initValues}
            onSubmit={onFormSubmit}
          >
            {() => (
              <Form className='w-100 pb-10'>
                <PaymentMethodForm />
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-10 mb-5'
                  disabled={mutation.isLoading}
                >
                  {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                  {mutation.isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default EditPaymentMethod
