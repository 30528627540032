import {useEffect, useMemo, useState} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import moment from 'moment'

import {FallbackView} from '../../../../_metronic/partials'
import {useSupplierProducts} from '../../../hooks/useSupplierProducts'
import {useSupplierCampaigns} from '../../../hooks/useSupplierCampaigns'
import {Card} from 'react-bootstrap-v5'

const OfferPriceForm = (props: any) => {
  const {setFieldValue, values}: any = useFormikContext()
  const [roomOptions, setRoomOptions] = useState([])
  const {data: campaigns, isLoading} = useSupplierCampaigns('offers')
  const {data: products, isLoading: isProductsLoading} = useSupplierProducts()

  const roomOptionsMemoized = useMemo(() => {
    const property = products?.find((p: any) => p.id === Number(values.property))
    return property?.rooms ?? []
  }, [products, values.property])

  useEffect(() => {
    setRoomOptions(roomOptionsMemoized)
  }, [roomOptionsMemoized])

  if (isProductsLoading || isLoading) return <FallbackView />

  const handleHotelChange = (propertyId: any) => {
    const selectedHotel = products.find((hotel: any) => hotel.id === parseInt(propertyId))
    setFieldValue('property', propertyId)
    setRoomOptions(selectedHotel.rooms)
  }

  return (
    <Card>
      <Card.Body>
        {props.userType.toLowerCase() === 'travel' ? (
          <div className='fv-row mb-10'>
            <label className='form-label required'>Select your package</label>
            <Field as='select' name='packageSlug' className='form-select' required>
              <option value=''>Choose your options</option>
              {products.map((item: any) => {
                return <option value={item.slug}>{item?.title}</option>
              })}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='packageSlug' />
            </div>
          </div>
        ) : (
          <>
            <div className='fv-row mb-10'>
              <label className='form-label required'>Select your hotels</label>
              <Field
                as='select'
                name='property'
                className='form-select'
                required
                onChange={(e: any) => handleHotelChange(Number(e.target.value))}
              >
                <option value='' disabled>
                  Choose your options
                </option>
                {products.map((item: any) => {
                  return <option value={item.id}>{item?.title}</option>
                })}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='property' />
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label required'>Select your room</label>
              <Field as='select' name='roomSlug' className='form-select' required>
                <option value=''>Choose your options</option>
                {roomOptions?.map((room: any) => (
                  <option value={room.slug}>{room?.title}</option>
                ))}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='roomSlug' />
              </div>
            </div>
          </>
        )}
        <div className='mb-10'>
          <label className='form-label required'>Offer price</label>
          <Field
            type='number'
            className='form-control'
            placeholder='Offer price'
            name='offerPrice'
            required
          />
          <div className='text-danger'>
            <ErrorMessage name='offerPrice' />
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label required'>Actives At Date and time</label>
          <Field
            type='datetime-local'
            className='form-control'
            name='activesAt'
            min={moment(new Date()).format('YYYY-MM-DD')}
            required
          />
          <div className='text-danger'>
            <ErrorMessage name='activesAt' />
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label required'>Expires At Date and time</label>
          <Field
            type='datetime-local'
            className='form-control'
            name='expiresAt'
            min={moment(values.activesAt).add(1, 'days').format('YYYY-MM-DD')}
            required
          />
          <div className='text-danger'>
            <ErrorMessage name='expiresAt' />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label'>Campaign</label>
          <Field as='select' name='campaignCode' className='form-select'>
            <option value=''>Choose your options</option>
            {campaigns?.map((campaign: any) => {
              return (
                <option key={campaign.id} value={campaign.code}>
                  {campaign.name}
                </option>
              )
            })}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='campaignCode' />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default OfferPriceForm
