const bookingActions = (checkStatus: any) => {
  switch (checkStatus?.toLowerCase()) {
    case 'pending':
      return (
        <>
          <option value='approve'>Approve</option>
          <option value='reject'>Reject</option>
        </>
      )
    case 'approved':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='reject'>Reject</option>
        </>
      )
    case 'rejected':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='approve'>Approve</option>
          {/* <option value='approve_update'>Approve Updated</option> */}
        </>
      )
    case 'cancellation_pending':
      return (
        <>
          <option value='cancellation_approve'>Cancellation Approve</option>
          <option value='cancellation_reject'>Cancellation Reject</option>
        </>
      )
    case 'cancellation_rejected':
      return (
        <>
          <option value='cancellation_pending'>Cancellation Pending</option>
          <option value='cancellation_approve'>Cancellation Approve</option>
        </>
      )
    case 'cancellation_approved':
      return (
        <>
          <option value='cancellation_reject'>Cancellation Reject</option>
          <option value='cancellation_pending'>Cancellation Pending</option>
        </>
      )
    case 'payment_pending':
      return (
        <>
          <option value='approve'>Approve</option>
          <option value='reject'>Reject</option>
        </>
      )
    case 'payment_verification_failed':
      return (
        <>
          <option value='payment_pending'>Payment Pending</option>
          <option value='reject'>Reject</option>
          <option value='approve'>Approve</option>
          <option value='payment_failed'>Payment failed</option>
        </>
      )
    case 'payment_verification_pending':
      return (
        <>
          <option value='payment_pending'>Payment Pending</option>
          <option value='payment_verification_failed'>Payment Verification Failed"</option>
          <option value='payment_failed'>Payment failed</option>
        </>
      )
    // case 'approve_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='reject_update'>Update Rejected</option>
    //     </>
    //   )
    // case 'reject_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='approve_update'>Approve Updated</option>
    //     </>
    //   )
    default:
      return null
  }
}

const productActions = (checkStatus: any) => {
  switch (checkStatus?.toLowerCase()) {
    case 'pending':
      return (
        <>
          <option value='verify'>Verify</option>
          <option value='reject'>Reject</option>
          <option value='delete'>Delete</option>
        </>
      )
    case 'verified':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='delete'>Delete</option>
          {/* <option value='reject_update'>Update Rejected</option> */}
        </>
      )
    case 'rejected':
      return (
        <>
          <option value='pending'>Pending</option>
          {/* <option value='verify_update'>Verify Update</option> */}
          <option value='delete'>Delete</option>
        </>
      )
    case 'deleted':
      return <option value='restore'>Restore</option>
    case 'restored':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='verify'>Verify</option>
          <option value='reject'>Reject</option>
          <option value='delete'>Delete</option>
        </>
      )
    // case 'verify_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='reject_update'>Update Rejected</option>
    //       <option value='delete'>Delete</option>
    //     </>
    //   )
    // case 'reject_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='verify_update'>Verify Updated</option>
    //     </>
    //   )
    // case 'update_on_review':
    //   return (
    //     <>
    //       <option value='approve'>Approve</option>
    //       <option value='delete'>Delete</option>
    //     </>
    //   )
    default:
      return null
  }
}

const verifySupplierActions = (checkStatus: any) => {
  switch (checkStatus?.toLowerCase()) {
    case 'pending':
      return (
        <>
          <option value='verify'>Verify</option>
          <option value='reject'>Reject</option>
          <option value='suspend'>Suspend</option>
          {/* <option value='delete'>Delete</option> */}
        </>
      )
    case 'verified':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='suspend'>Suspend</option>
          {/* <option value='delete'>Delete</option> */}
          {/* <option value='reject_update'>Update Rejected</option> */}
        </>
      )
    case 'rejected':
      return (
        <>
          <option value='pending'>Pending</option>
          {/* <option value='verify_update'>Verify Update</option> */}
          {/* <option value='delete'>Delete</option> */}
        </>
      )
    // case 'deleted':
    //   return <option value='restore'>Restore</option>
    case 'restored':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='verify'>Verify</option>
          {/* <option value='reject'>Reject</option> */}
          {/* <option value='delete'>Delete</option> */}
        </>
      )
    case 'suspended':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='verify'>Verify</option>
          {/* <option value='delete'>Delete</option> */}
        </>
      )
    // case 'verify_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='reject_update'>Update Rejected</option>
    //       <option value='delete'>Delete</option>
    //     </>
    //   )
    // case 'reject_updated':
    //   return (
    //     <>
    //       <option value='pending'>Pending</option>
    //       <option value='verify_update'>Verify Updated</option>
    //     </>
    //   )
    default:
      return <option value='verify'>Verify</option>
  }
}

const productApprovalActions = (checkStatus: any) => {
  switch (checkStatus?.toLowerCase()) {
    case 'pending':
      return (
        <>
          <option value='approve'>Approve</option>
          <option value='reject'>Reject</option>
        </>
      )
    case 'approved':
      return (
        <>
          <option value='pending'>Approve</option>
          <option value='reject'>Reject</option>
        </>
      )
    case 'rejected':
      return (
        <>
          <option value='pending'>Pending</option>
          <option value='approve'>Approve</option>
          {/* <option value='approve_update'>Approve Updated</option> */}
        </>
      )
    default:
      return null
  }
}

export const actionServices = {
  bookingActions,
  verifySupplierActions,
  productActions,
  productApprovalActions,
}
