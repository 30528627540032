/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage} from 'formik'
import {Col, Row} from 'react-bootstrap-v5'

const MainContact = () => {
  return (
    <div className='w-100'>
      <div className='mb-5'>
        <h2>Main Contact</h2>
      </div>
      {/* managing director form starts */}
      <div className='managing-director-form'>
        <h6 className='my-10'>Managing Director</h6>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3 required'>First Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='directorFirstName'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='directorFirstName' />
              </div>
            </Col>
            <Col>
              <label className='form-label mb-3 required'>Last Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='directorLastName'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='directorLastName' />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* managing director form ends */}
      {/* company contact person starts */}
      <div className='company-contact-person'>
      <h6 className='my-10'>Company Contact Person</h6>
        <Row>
          <Col className='mb-10'>
            <label className='form-label mb-3 required'>First Name</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='firstName'
              placeholder='Enter First Name'
              // required
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='firstName' />
            </div>
          </Col>
          <Col className='mb-10'>
            <label className='form-label mb-3 required'>Last Name</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='lastName'
              placeholder='Enter Last Name'
              // required
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='lastName' />
            </div>
          </Col>
        </Row>
        <div className='mb-10 fv-row'>
          <label className='form-label mb-3 required'>Phone Number</label>
          <Field
            type='number'
            className='form-control form-control-lg form-control-solid'
            name='phoneNumber'
            placeholder='Enter your contact person phone number'
            // required
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='phoneNumber' />
          </div>
        </div>
      </div>
      {/* company contact person ends */}
      {/* company address starts */}
      <div className='company-address-wrapper'>
        <h4 className='mb-10 fw-500'>Company address</h4>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3 required'>Country</label>
              <Field as='select' name='country' className='form-select form-select-solid' disabled>
                <option value='nepal'>Nepal</option>
                {/* <option value='nepal'>Nepal</option> */}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='country' />
              </div>
            </Col>
            <Col>
              <label className='form-label mb-3 required'>City</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='city'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='city' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3 required'>Address</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='address'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='address' />
              </div>
            </Col>
            <Col>
              <label className='form-label mb-3 required'>State</label>
              <Field
                as='select'
                name='state'
                className='form-select form-select-solid'
              >
                <option value='' disabled>
                  Choose
                </option>
                <option value='Province No. 1'>Province No. 1 </option>
                <option value='Province No. 2'>Province No. 2 </option>
                <option value='Bagmati'>Bagmati</option>
                <option value='Gandaki'>Gandaki</option>
                <option value='Lumbini'>Lumbini</option>
                <option value='Karnali'>Karnali</option>
                <option value='Lumbini'>Lumbini</option>
                <option value='Sudurpashchim'>Sudurpashchim</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='state' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='mb-10'>
          <Row>
            <Col>
              <label className='form-label mb-3'>Zip Code (optional)</label>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='zipCode'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='zipCode' />
              </div>
            </Col>
            <Col>
              <label className='form-label mb-3'>Currency</label>
              <Field as='select' name='currency' className='form-select form-select-solid' disabled>
                <option value='npr'>NPR</option>
                {/* <option value='npr'>Npr</option> */}
              </Field>
              {/* <div className='text-danger mt-2'>
              <ErrorMessage name='currency' />
            </div> */}
            </Col>
          </Row>
        </div>
      </div>
      {/* company address ends */}
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Social Links</label>
        <div className='mt-2'>
          <Field
            type='link'
            className='form-control form-control-lg form-control-solid'
            name='social.facebook'
            placeholder='your facebook account'
            // requried
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='social.facebook' />
          </div>
        </div>
        <div className='mt-2'>
          <Field
            type='link'
            className='form-control form-control-lg form-control-solid'
            name='social.instagram'
            placeholder='your instagram account'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='social.instagram' />
          </div>
        </div>
        <div className='mt-2'>
          <Field
            type='link'
            className='form-control form-control-lg form-control-solid'
            name='social.twitter'
            placeholder='your twitter account'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='social.twitter' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainContact
