import { useQuery } from 'react-query'

import { paymentServices } from '../services/api/payment'

type PaymentRequests = {
  page: number, status: string
}

const usePaymentRequests = ({ page, status }: PaymentRequests) => {
  return useQuery(['payment-requests', page, status], () => paymentServices.getPaymentRequests(page, status))
}

export { usePaymentRequests }
