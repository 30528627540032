export const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const multipleImages = async (e: any, setFieldValue: any, key: any) => {
  if (e.target.files && e.target.files.length > 0) {
    const newImagesPromises = []
    for (let i = 0; i < e.target.files.length; i++) {
      newImagesPromises.push(convertToBase64(e.target.files[i]))
    }
    const newImages = await Promise.all(newImagesPromises)
    setFieldValue(key, newImages)
  }
}