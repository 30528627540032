import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { authenticationService } from "../services/api/authentication"

export const useChangePassword = () => {
    return useMutation(authenticationService.changePassword , {
        onSuccess : () => {
            toast.success(`Password has been changed successfully`)
        },
        onError : (error:any) => {
            toast.error(`${error?.response?.data?.error?.message || error?.response?.statusText}`)
        }
    })
}