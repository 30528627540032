import axios from '../../../setup/axios/SetupAxios'

const uploadAttachment = (value: any) => axios.post(`/attachments`, { images: value })

const removeAttachment = (id: number) => axios.delete(`/attachments/${id}`)

const guestUploadAttachment = (value: any) => axios.post(`/attachment/guest`, { images: value })

const guestRemoveAttachment = (id: number) => axios.delete(`/attachment/guest/${id}`)

export const attachements = {
    uploadAttachment,
    removeAttachment,
    guestUploadAttachment,
    guestRemoveAttachment
}