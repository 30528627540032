import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { LoadingView } from '../LoadingView'
import { Error404 } from '../../modules/errors/components/Error404'
import { ProfileHeader } from './components/ProfileHeader'
import { ProfileDetails } from './components/account/ProfileDetails'
import { useGetCompany } from '../../hooks/useGetCompany'
import ChangePassword from './components/settings/ChangePassword'

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Account',
        path: '/supplier/company',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const SupplierProfilePage: React.FC = () => {

    const { data: company, isLoading, isError } = useGetCompany();

    if (isLoading) {
        return <LoadingView />
    }
    if (isError) {
        return <Error404 />
    }

    const data = company

    return (
        <>
            {
                data.user.company_details.contact_person !== null &&
                <ProfileHeader data={data} />
            }
            <Switch>
                <Route path='/supplier/company/overview'>
                    <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
                    <ProfileDetails data={data} />
                    {/* <Overview1 data={data} /> */}
                </Route>
                <Route path='/supplier/company/settings'>
                    <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
                    <ChangePassword />
                </Route>
                <Redirect from='/supplier/company' exact={true} to='/supplier/company/overview' />
                <Redirect to='/supplier/company/overview' />
            </Switch>
        </>
    )
}

export default SupplierProfilePage
