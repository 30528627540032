import axios from '../../../setup/axios/SetupAxios'

/* ********************************************** 
            Supplier Coupon routes
********************************************** */

const postCoupon = async (value: any) => {
  const {
    name,
    couponCode,
    discountType,
    discountValue,
    discountPercent,
    maxDiscountCap,
    minimumSpend,
    couponUsageLimit,
    perUserLimit,
    isGuestAllowed,
    expiresAt,
    couponableId,
    // couponType,
    activesAt,
    campaignCode,
    remarks
  } = value
  const response = await axios.post(`/supplier/coupons`, {
    name,
    couponable_type: value.couponType,
    coupon_code: couponCode,
    discount_type: discountType,
    discount_value: discountValue,
    discount_percent: discountPercent,
    max_discount_cap: maxDiscountCap,
    minimum_spend: minimumSpend,
    coupon_usage_limit: couponUsageLimit,
    per_user_limit: perUserLimit,
    is_guest_allowed: !!isGuestAllowed,
    actives_at: activesAt,
    expires_at: expiresAt,
    couponable_id: couponableId,
    campaign_code :campaignCode,
    remarks
  })
  return response
}

const editCoupon = async ({ ...value }: any) => {

  const {
    name,
    couponCode,
    discountType,
    discountValue,
    discountPercent,
    maxDiscountCap,
    minimumSpend,
    couponUsageLimit,
    perUserLimit,
    isGuestAllowed,
    activesAt,
    expiresAt,
    couponableId,
    campaignCode,
    remarks
  } = value
  const response = await axios.put(`/supplier/coupons/${value.id}`, {
    name,
    coupon_code: couponCode,
    discount_type: discountType,
    discount_value: discountValue,
    discount_percent: discountPercent,
    max_discount_cap: maxDiscountCap,
    minimum_spend: minimumSpend,
    coupon_usage_limit: couponUsageLimit,
    per_user_limit: perUserLimit,
    is_guest_allowed: !!isGuestAllowed,
    actives_at: activesAt,
    expires_at: expiresAt,
    couponable_id: couponableId,
    couponable_type: value.couponType,
    campaign_code :campaignCode,
    remarks
  })
  return response
}

const getCoupon = async (id: number) => {
  const { data } = await axios.get(`/supplier/coupons/${id}`)
  return data
}

const getCoupons = async (status: string, page: number) => {
  const { data } = await axios.get(`/supplier/coupons?status=${status}&page=${page}`)
  return data
}

const deleteCoupon = async (id: number) => {
  const response = await axios.delete(`/supplier/coupons/${id}`)
  return response.data
}

const getCouponType = async (userType: any) => {
  const { data } = await axios.get(`/supplier/coupons/products`, {
    params: {
      product: `${userType}`,
    },
  })
  return data
}

const couponStatus = async ({ active, id }: any) => {
  const response = await axios.patch(`/coupons/status/${id}`, { is_active: Number(active) })
  return response
}

export const couponServices = {
  postCoupon,
  editCoupon,
  getCoupon,
  getCoupons,
  deleteCoupon,
  getCouponType,
  couponStatus,
}
