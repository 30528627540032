import { Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import AddEdit from './components/AddEdit'
import OfferPriceList from './components/OfferPriceList'

const offerBreadCrumbs = [
  {
    title: 'Offers',
    path: '/offers/offer-price',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OfferMainPage = () => {
  return (
    <Switch>
      <Route exact path='/offers/offer-price'>
        <PageTitle breadcrumbs={offerBreadCrumbs}>All Offers</PageTitle>
        <OfferPriceList />
      </Route>
      <Route exact path='/offers/offer-price/add'>
        <PageTitle breadcrumbs={offerBreadCrumbs}>Add Offers</PageTitle>
        <AddEdit />
      </Route>
      <Route exact path='/offers/offer-price/:id/edit'>
        <PageTitle breadcrumbs={offerBreadCrumbs}>Edit Offers</PageTitle>
        <AddEdit />
      </Route>
    </Switch>
  )
}

export default OfferMainPage
