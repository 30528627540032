import React from 'react'

const SelectFilter = (props: any) => {
  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      value={props.status}
      onChange={(e) => props.setStatus(e.target.value)}
    >
      <option value="">All</option>
      {
        props.options.map((item: any, index: number) => (
          <option key={index} value={item.value?.toLowerCase()} className="text-capitalize">{item.value}</option>
        ))
      }
    </select>
    )
}

export default SelectFilter