/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/hooks/useAuth'
import {ROLE} from '../../../../app/_helpers/Role'

export function AsideMenuMain() {
  const {session} = useAuth()
  const role = session?.user?.role
  const intl = useIntl()
  return (
    <>
      {role === ROLE.Admin ? (
        <AsideMenuItem
          to='/admin/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {role === ROLE.TravelSupplier && (
        <>
          <AsideMenuItemWithSub
            to='/crafted/travel'
            title='Travel'
            icon='/media/icons/duotune/communication/com006.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/package/package-management'
              title='Package Management'
              hasBullet={true}
            />
            <AsideMenuItem to='/bookings' title='Bookings' hasBullet={true} />
            {/* <AsideMenuItem to='/hotel/reviews' title='Reviews' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/payment-methodss'
            title='Payment'
            icon='/media/svg/payment-methods/visa.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/payment-method' title='Payment Method' hasBullet={true} />
            <AsideMenuItem to='/payment-request' title='Payment Requests' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/offers/coupon'
            title='Offers'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/offers/coupon' title='Coupon' hasBullet={true} />
            <AsideMenuItem to='/offers/offer-price' title='Offer price' hasBullet={true} />
            {/* <AsideMenuItem to='/payment-request' title='Payment Requests' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
        </>
      )}
      {role === ROLE.HotelSupplier && (
        <>
          <AsideMenuItemWithSub
            to='/crafted/hotel'
            title='Property'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/hotel/property' title='Property Management' hasBullet={true} />
            <AsideMenuItem to='/bookings' title='Bookings' hasBullet={true} />
            {/* <AsideMenuItem to='/hotel/reviews' title='Reviews' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/payment-methods'
            title='Payment'
            icon='/media/svg/payment-methods/visa.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/payment-method' title='Payment Method' hasBullet={true} />
            <AsideMenuItem to='/payment-request' title='Payment Requests' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/offers/coupon'
            title='Offers'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/offers/coupon' title='Coupon' hasBullet={true} />
            <AsideMenuItem to='/offers/offer-price' title='Offer price' hasBullet={true} />
            {/* <AsideMenuItem to='/payment-request' title='Payment Requests' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
        </>
      )}
      {role === ROLE.Admin && (
        <>
          <AsideMenuItem
            fontIcon='bi-layers'
            to='/admin/user/user-details'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Users'
          />
          <AsideMenuItem
            to='/admin/user-admin/admin-user-lists'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Admin Users'
            fontIcon='bi-person'
          />
          <AsideMenuItem
            to='/admin/supplier/suppliers-details'
            icon='/media/icons/duotune/coding/cod001.svg'
            title='Suppliers'
            fontIcon='bi-person'
          />
          {/* <AsideMenuItem
            to='/admin/room/room-details'
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            title='Rooms'
            fontIcon='bi-person'
          /> */}
          <AsideMenuItemWithSub
            to='/admin/room/room-details'
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            title='Rooms'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/admin/room/room-details' title='All Rooms' hasBullet={true} />
            <AsideMenuItem
              to='/admin/room/product-approvals'
              title='Approval Rooms'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/admin/package/package-details'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            title='Packages'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/admin/package/package-details'
              title='All Packages'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/admin/package/product-approvals'
              title='Approval Packages'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          {/* <AsideMenuItem
            to='/admin/package/package-details'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            title='Package'
            fontIcon='bi-person'
          /> */}
          <AsideMenuItemWithSub
            to='/admin/property/property-details'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            title='Property'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/admin/property/property-details'
              title='All Property'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/admin/property/product-approvals'
              title='Approval Properties'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          {/* <AsideMenuItem
            to='/admin/property/property-details'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            title='Property'
            fontIcon='bi-person'
          /> */}
          <AsideMenuItem
            to='/admin/bookings'
            icon='/media/icons/duotune/ecommerce/ecm001.svg'
            title='Bookings'
            fontIcon='bi-person'
          />
          <AsideMenuItem
            to='/admin/blogs'
            fontIcon='bi-person'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title='Blogs'
          />
          <AsideMenuItem
            to='/admin/payout/payment-request'
            title='Payment-requests'
            icon='/media/svg/payment-methods/visa.svg'
            fontIcon='bi-person'
          />
          <AsideMenuItemWithSub
            to='/admin/offer/coupon'
            title='Offers'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/admin/offer/coupons' title='Coupon' hasBullet={true} />
            <AsideMenuItem to='/admin/offer/offer-price' title='Offer price' hasBullet={true} />
            {/* <AsideMenuItem to='/payment-request' title='Payment Requests' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/admin/campaigns'
            title='Campaigns'
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            fontIcon='bi-person'
          />
          <AsideMenuItem
            to='/admin/newsletter'
            title='Newsletter Subscriptions'
            icon='/media/svg/payment-methods/visa.svg'
            fontIcon='bi-person'
          />
          <AsideMenuItemWithSub
            to='/admin/feature'
            title='Settings'
            icon='/media/icons/duotune/general/gen019.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/admin/feature-toggle' title='Payment methods'  icon='/media/svg/payment-methods/visa.svg'
 />
            <AsideMenuItem to='/admin/cities' title='Cities' hasBullet={true} />
            <AsideMenuItem to='/admin/amenities' title='Amenities' hasBullet={true} />
            <AsideMenuItem to='/admin/property-services' title='Property Services' hasBullet={true} />
            <AsideMenuItem to='/admin/banks' title='Banks' hasBullet={true} />
            <AsideMenuItemWithSub
              to='/admin/feature-products'
              title='Homepage settings'
              icon='/media/icons/duotune/ecommerce/ecm007.svg'
              fontIcon='bi-person'
            >
              <AsideMenuItem
                to='/admin/feature-products/hot-deals'
                title='Hot deals'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/admin/feature-products/popular'
                title='Popular products'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          </AsideMenuItemWithSub>
{/* 
          <AsideMenuItemWithSub
            to='/admin/reportings'
            title='Reportings'
            icon='/media/icons/duotune/general/gen019.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/admin/supplier/reportings' title='Supplier' hasBullet={true} />
            <AsideMenuItem to='/admin/user/reportings' title='User' hasBullet={true} />
          </AsideMenuItemWithSub> */}

          {/* <AsideMenuItem
            to='/admin/news-letter'
            title='Newsletter'
            icon='/media/svg/payment-methods/visa.svg'
            fontIcon='bi-person'
          /> */}
          {/* <AsideMenuItemWithSub
            to='/apps/chat'
            title='Chat'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/communication/com012.svg'
          >
            <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
            <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
            <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
          </AsideMenuItemWithSub> */}
        </>
      )}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
              {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* role auth routes */}
      {/* </div>  */}
    </>
  )
}
