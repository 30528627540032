import * as Yup from 'yup'

const CouponSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(255, 'Maximum 255 characters')
      .required('Coupon Name is required'),
      couponCode: Yup.string()
      .min(5, 'Minimum 5 characters')
      .max(10, 'Maximum 10 characters')
      .required('Coupon code is required'),
      discountType: Yup.string()
      // .min(3, 'Minimum 3 characters')
      .max(255, 'Maximum 255 characters')
      .required('Discount type is required'),
      discountValue: Yup.number()
      // .min(1, 'Minimum 3 characters')
      .max(999999, 'Maximum 999999 characters')
      .nullable(),
      discountPercent: Yup.number()
      // .min(1, 'Minimum percent is 1')
      .max(100, 'Maximum percent is 100')
      .nullable(),
      maxDiscountCap: Yup.number()
      // .min(1, 'Minimum 1 characters')
      .max(999999, 'Maximum 999999 characters')
      .nullable(),
      minimumSpend: Yup.number()
      .min(1, 'Minimum 1 characters')
      .max(999999, 'Maximum 999999 characters')
      .nullable(),
      couponUsageLimit: Yup.number()
      .min(1, 'Minimum 1 characters')
      .max(999999, 'Maximum 999999 characters')
      .nullable(),
      perUserLimit: Yup.number()
      .min(1, 'Minimum 1 characters')
      .max(1000, 'Maximum 1000 characters')
      .nullable(),
      remarks: Yup.string().when('discountPercent', {
        is: (field1Value:any) => field1Value === 100,
        then: Yup.string().required('Remarks is required'),
        otherwise: Yup.string().nullable(),
      }),
    //   is_guest_allowed: Yup.string()
    //   .min(3, 'Minimum 3 characters')
    //   .max(255, 'Maximum 255 characters')
    //   .required('Property Name is required'),
    //   expires_at: Yup.string()
    //   .min(3, 'Minimum 3 characters')
    //   .max(255, 'Maximum 255 characters')
    //   .required('Property Name is required'),
})
export {CouponSchema }