import axios from '../../../setup/axios/SetupAxios'

const getBookings = async (status: string, page: number) => {
  const { data } = await axios.get(`/supplier/bookings?page=${page}&status=${status}`)
  return data
}

const getBooking = async (bookingReference: string) => {
  const { data } = await axios.get(`/supplier/bookings/${bookingReference}`)
  return data
}

const approveBooking = async (bookingReference: any) => {
  const response = await axios.patch(`/supplier/bookings/${bookingReference}/approve`)
  return response
}

const rejectBooking = async (bookingReference: any) => {
  return await axios.patch(`/supplier/bookings/${bookingReference}/cancel`)
}

const supplierBookingAction = ({ bookingReference, options }: any) => {
  return axios({
    method: 'patch',
    url: `/supplier/bookings/${bookingReference}`,
    data: {},
    headers: { 'suyatra-supplier-action': options },
  })
}

export const bookingServices = {
  getBooking,
  getBookings,
  approveBooking,
  rejectBooking,
  supplierBookingAction,
}
