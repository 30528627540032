import React from 'react'
import { Form, Formik } from 'formik'

import { ProfileDetailsSchema } from '../../../../_helpers/shared/models/SupplierProfileSchema'
import SupplierDetailsForm from './SupplierDetailsForm'
import { useEditCompany } from '../../../../hooks/useEditCompany'
import { useAuth } from '../../../../hooks/useAuth'

interface CompanyProfile {
    companyName: string,
    email: string,
    companyType: string,
    website: string | any,
    activity: string[],
    directorFirstName: string,
    directorLastName: string,
    address: string,
    city: string,
    state: string,
    country: string,
    currency: string,
    zipCode: number,
    social: {
        facebook: string,
        instagram: string,
        twitter: string
    },
    phoneNumber: number,
    contactPerson: string,
    contactPersonPhone: number,
    officeHoursFrom: number,
    officeHoursTo: number,
    companyImage: string,
    profileImage: string,
    verificationInformation: string[],
};

const ProfileDetails = ({ data }: any) => {

    const mutation = useEditCompany();
    const {session , setAuth} = useAuth();
    
    const token = session.token;
    var firstName = data?.user?.company_details?.managing_director?.split(' ').slice(0, -1).join(' ');
    var lastName = data?.user?.company_details?.managing_director?.split(' ').slice(-1).join(' ');

    const initialValues: CompanyProfile = {
        companyName: data?.user?.name,
        email: data?.user?.email,
        companyType: data?.user?.type?.toLowerCase(),
        website: data?.user.company_details?.website || '',
        activity: data?.user?.company_details?.activities || [],
        directorFirstName: firstName || '',
        directorLastName: lastName || '',
        address: data?.user?.company_details?.address || '',
        city: data?.user?.company_details?.city || '',
        state: data?.user?.company_details?.state || '',
        country: data?.user?.company_details?.country || '',
        currency: 'NPR',
        zipCode: data?.user?.company_details?.postal_code || '',
        social: {
            facebook: '',
            instagram: '',
            twitter: ''
        },
        phoneNumber: data?.user?.company_details?.company_phone || '',
        contactPerson: data?.user?.company_details?.contact_person || '',
        contactPersonPhone: data?.user?.company_details?.contact_person_phone || '',
        officeHoursFrom: data?.user?.company_details?.office_hours[0] || '',
        officeHoursTo: data?.user?.company_details?.office_hours[1] || '',
        companyImage: '',
        profileImage: '',
        verificationInformation: [],
    };

    const onFormSubmit = (values: CompanyProfile) => {
        mutation.mutate(values , {
            onSuccess : (data) => {
                const session = { token, user: data?.data }
                setAuth(session)
            }
        })
    };

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Company Details</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <Formik validationSchema={ProfileDetailsSchema} initialValues={initialValues} onSubmit={onFormSubmit}>
                    {() => (
                        <Form className='w-100 pb-10'>
                            <SupplierDetailsForm data={data} />
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn btn-lg btn-primary w-10 m-5 text-center'
                                disabled={mutation.isLoading}
                            >
                                {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                                {mutation.isLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>

        </div >
    )
}

export { ProfileDetails }
