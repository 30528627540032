import React, { FC } from 'react'
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { FallbackView } from '../../../_metronic/partials';
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets';
import { useGetCompany } from '../../hooks/useGetCompany';
import { useUserRole } from '../../hooks/useUserRole'
import { ROLE } from '../../_helpers/Role'
import Bookings from '../bookings/components/Bookings';

const DashboardPage: FC = () => {
  const { data: supplier, isLoading }:any = useGetCompany();

  if (isLoading) return <FallbackView />;
  const payout = supplier?.payout;

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='row g-5 g-xl-8'>
        {!supplier?.user?.company_details.is_profile_completed && (
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTSVG
                path='icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4'
              />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                  <div className='fs-6 text-gray-600'>
                    Your Profile is not completed yet. To update your profile, please
                    <Link className='fw-bolder' to='/supplier/company'>
                      {' '}
                      Add company details
                    </Link> 
                    .
                  </div>
                </div>
              </div>
            </div>
          )}
          {supplier?.user?.payment_methods?.length === 0 && (
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTSVG
                path='icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4'
              />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                  <div className='fs-6 text-gray-600'>
                    Your payment is not registered yet. To start using fetching money, please
                    <Link className='fw-bolder' to='/payment-method'>
                    {' '}
                      Add Payment Method
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon=''
              color='success'
              iconColor='primary'
              title={`Rs. ${payout?.vat_amount}`}
              description='Vat Amount'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='dark'
              iconColor='white'
              title={`Rs. ${payout?.total_earned_amount}`}
              description='Total Earned Amount'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon=''
              color='primary'
              iconColor='primary'
              description='Total Paid'
              link="/payment-request"
              title={`Rs. ${payout?.total_paid}`}
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='secondary'
              iconColor='white'
              title={`Rs. ${payout?.pending_accumulated_amount}`}
              description='Pending Accumulated Amount'
            />
          </div>
        </div>
      </div>
      <div className='row gy-5 gx-xl-8'>
        <div>
          <Bookings />
        </div>
      </div>
  
    </>
  )
}

const SupplierDashboard: FC = () => {
    useUserRole([ROLE.TravelSupplier, ROLE.Admin, ROLE.HotelSupplier]);
    const intl = useIntl()

  return (
    <>
    <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
    <DashboardPage />
  </>
  )
}

export default SupplierDashboard