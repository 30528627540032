import axios from '../../../setup/axios/SetupAxios'

import { password } from '../../pages/supplierProfile/components/settings/ChangePassword'

const forgotPassword = async (email: string) => await axios.post(`/forgot-password`, { email })

const resetPassword = async ({ email, token, password, password_confirmation }: any) => {
  return await axios.post(`/reset-password`, { email, token, password, password_confirmation })
}

const adminLogin = async (email: string, password: string) => {
  return axios.post(`/admin/login`, { email, password })
}

const login = async (email: string, password: string) => {
  return axios.post(`/supplier/login`, { email, password })
}

const register = async (values: any) => {
  return axios.post(`/supplier/register`, {
    name: values.companyName,
    type: values.companyType,
    email: values.emailAddress,
    password: values.password,
    password_confirmation: values.confirmPassword,
  })
}

const editSupplierById = (values:any) => {
  return axios.put(`/supplier/register/${values.userId}`, {
    // name: values.companyName,
    // type: values.companyType,
    // email: values.emailAddress,
    // password: values.password,
    // password_confirmation: values.confirmPassword,
    website: values.website,
    activities: values.activity.map((item: any) => item.value),
    managing_director: `${values.directorFirstName} ${values.directorLastName}`,
    address: values.address,
    city: values.city,
    state: values.state,
    country: 'nepal',
    currency: 'npr',
    postal_code: values.zipCode,
    social: values.social,
    contact_person: `${values.firstName} ${values.lastName}`,
    company_phone: values.companyNumber,
    contact_person_phone: values.phoneNumber,
    office_hours: [values.officeHoursFrom, values.officeHoursTo],
    images: [values.companyImage],
    profile_image: values.companyImage,
    cover_image: values.companyImage,
    verification_information: values.verificationDocuments,
  })
}

const changePassword = async (values: password) => {
  return axios.post(`/password/change`, {
    current_password: values.currentPassword,
    new_password: values.newPassword,
    new_password_confirmation: values.newPasswordConfirmation
  })
}

export const authenticationService = {
  adminLogin,
  login,
  register,
  resetPassword,
  forgotPassword,
  changePassword,
  editSupplierById
}
