import React from 'react'
import moment from 'moment'
import {Link, useRouteMatch} from 'react-router-dom'

import {statusCheck} from '../../../_helpers/statusCheck'

const BookingItem = ({bookingDetail, index}: any) => {
  const match = useRouteMatch()

  return (
    <tr key={bookingDetail?.id}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input className='form-check-input widget-13-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <Link
          to={`bookings/${bookingDetail?.booking_reference}/view`}
          className='text-dark fw-bolder text-hover-primary fs-6'
        >
          {index + 1}
        </Link>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light'>
              <img
                src={bookingDetail?.product?.thumbnail?.url}
                className='h-75 align-self-end w-100 h-100'
                alt={bookingDetail?.product?.thumbnail?.name}
              />
            </span>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <Link
              to={`bookings/${bookingDetail?.booking_reference}/view`}
              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
            >
              {bookingDetail.product?.property_name && `${bookingDetail.product?.property_name} /`}
            </Link>
            <Link
              to={`bookings/${bookingDetail?.booking_reference}/view`}
              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
            >
              {bookingDetail?.product?.title}
              <span
                className={`badge badge-light-${
                  new Date(bookingDetail.starts_at) >= new Date()
                    ? 'warning'
                    : new Date(bookingDetail.ends_at) <= new Date()
                    ? 'success'
                    : 'danger'
                } mx-3`}
              >
                {new Date(bookingDetail.starts_at) >= new Date()
                  ? 'Upcoming'
                  : new Date(bookingDetail.ends_at) <= new Date()
                  ? 'Completed'
                  : 'Today'}
              </span>
            </Link>
            <span className='text-muted fw-bold text-muted d-block fs-7'></span>
          </div>
        </div>
      </td>
      <td>
        <Link
          to={`${match?.url}/${bookingDetail?.booking_reference}/view`}
          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
        >
          {bookingDetail?.lead_traveller_name}
        </Link>
        <span className='text-muted fw-bold text-muted d-block fs-7'>
          {bookingDetail?.lead_traveller_phone}
        </span>
      </td>
      <td>
        <span className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          Rs. {bookingDetail?.total_amount}
        </span>
        <span className='text-muted fw-bold text-muted d-block fs-7'>
          Paid: Rs. {bookingDetail?.total_paid}
        </span>
      </td>
      <td className='text-dark fw-bolder text-hover-primary fs-6'>{bookingDetail.starts_at}</td>
      {/* <td className='text-dark fw-bolder text-hover-primary fs-6'>
      {item.ends_at}
    </td> */}
      <td className='text-dark fw-bolder text-hover-primary fs-6'>
        {moment(bookingDetail.created_at).format('YYYY-MM-DD')}
      </td>
      <td>{statusCheck(bookingDetail?.status)}</td>
    </tr>
  )
}

export default BookingItem
