import {useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { attachements } from '../services/api/image'

const useRemoveAttachment = (query: any) => {
    const queryClient = useQueryClient();
    return useMutation(attachements.removeAttachment, {
        onSuccess: () => {
            toast.success(`Image removed successfully`)
            queryClient.invalidateQueries(query)
        },
        onError: () => {
            toast.error(`Cannot delete image at the moment please try again later`)
        },
    })
}

export { useRemoveAttachment }
