import { useQuery } from 'react-query';

import { bookingServices } from '../services/api/booking'

const useBookings = (status: string, page: number) => {

    const getSupplierBookings = useQuery(["bookings", status, page], () => bookingServices.getBookings(status, page));

    return { getSupplierBookings }
}

export default useBookings