import React from 'react'
import { Button, Modal } from 'react-bootstrap-v5';

const ModalView = (props: any) => {
  const { show, handleClose, remove, isLoading } = props
  return (
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Remove selected items</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='danger' onClick={remove}>
            <span className='indicator-label'>
              {!isLoading && <span className='indicator-label'>Remove</span>}
              {isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  removing...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ModalView
