import {createContext, useState} from 'react'
import { getSession, setSessionInLocalStorage } from '../_helpers/crud/Auth'

export const AuthContext = createContext(getSession() || '')

const AuthProvider = (props) => {
  const auth = getSession()
  const [session, setSession] = useState(auth || '')
  const setAuth = (token) => {
    setSession(token)
    setSessionInLocalStorage(token)
  }
  const value = {
    session,
    setAuth,
  }
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

export default AuthProvider
