import React from 'react'
import {ErrorMessage, Field} from 'formik'
import CreatableSelect from 'react-select/creatable'

import {activities} from '../../../../utils/mock/activities'
import ImagePicker from '../../../../components/ImagePicker'
import MultipleImageUploader from '../../../../components/imageUpload/MultipleImageUploader'
import { states } from '../../../../utils/mock/data'

const SupplierDetailsForm = ({data}: any) => {
  return (
    <div className='card-body border-top p-9'>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>General Details</label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <label className='form-label mb-2 required'>Email</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter your email'
                name='email'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='email' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Name</label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <label className='form-label mb-2 required'>Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Company name'
                name='companyName'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='companyName' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          Company full address
        </label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>State</label>
              <Field
                as='select'
                name='state'
                className='form-select form-select-solid'
              >
                <option value='' disabled>
                  Choose
                </option>
                {
                    states.map((state , index) => (
                      <option key={state.value} value={state.value}>{state.label}</option>
                    ))
                  }
              </Field>
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='state' />
                </div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Country</label>
              <Field as='select' name='country' className='form-select form-select-solid'>
                <option>Nepal</option>
              </Field>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-md-4'></div>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Address</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Address'
                name='address'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='address' />
                </div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>City</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='city'
                placeholder='Enter your city'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='city' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          Company other details
        </label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Company Contact Number</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Company contact'
                name='phoneNumber'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='phoneNumber' />
                </div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Activities</label>
              <Field name='activity' required>
                {({form, field, meta}: any) => {
                  const {setFieldValue} = form
                  const {value} = meta
                  const options = value?.map((item: any) => ({label: item, value: item}))
                  return (
                    <CreatableSelect
                      isMulti
                      isClearable
                      defaultValue={options}
                      options={activities}
                      onChange={(val: any) => {
                        const services = val?.map((item: any) => item?.value)
                        return setFieldValue('activity', services)
                      }}
                    />
                  )
                }}
              </Field>
              {/* <Field name='activity mb-10'>
                        {({ form, meta }: any) => {
                            const { setFieldValue } = form
                            const { value } = meta
                            console.log("values", value);
                            const options = value?.map((item: any) => ({ label: item, value: item }))
                            return (
                                <CreatableSelect
                                    isClearable
                                    isMulti
                                    defaultValue={options}
                                    options={activities}
                                    onChange={(val: any) => {
                                        return setFieldValue('activity', val)
                                    }}
                                />
                            )
                        }}
                    </Field> */}
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-md-4'></div>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Currency</label>
              <Field
                as='select'
                name='currency'
                className='form-control form-control-lg form-control-solid'
              >
                <option>NPR</option>
                {/* <option value='npr'>Npr</option> */}
              </Field>
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'></div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2'>Postal code</label>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='zipCode'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='zipCode' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-md-4'></div>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Company Contact Person Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Company Contact Person Name'
                name='contactPerson'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='contactPerson' />
                </div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Company Contact Person Number</label>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder='Company Contact Person Number'
                name='contactPersonPhone'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactPersonPhone' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-md-4'></div>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Company Type</label>
              <Field
                as='select'
                name='companyType'
                className='form-control form-control-lg form-control-solid'
                disabled
              >
                <option value=''>Choose</option>
                <option value='hotel'>Hotel</option>
                <option value='travel'>Travel</option>
              </Field>
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'></div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Office hours</label>
              <Field
                type='time'
                className='form-control form-control-lg form-control-solid'
                name='officeHoursFrom'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='officeHoursFrom' />
              </div>
              <Field
                type='time'
                className='form-control form-control-lg form-control-solid'
                name='officeHoursTo'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='officeHoursTo' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          Company Social websites
        </label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <label className='form-label mb-2 required'>Company website</label>
              <Field
                type='url'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Company Website'
                name='website'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='website' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Director Full Name</label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>First Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='First name'
                name='directorFirstName'
              />
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='directorFirstName' />
                </div>
              </div>
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Last Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Last name'
                name='directorLastName'
              />

              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <ErrorMessage name='directorLastName' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Images</label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Profile Image</label>
              <ImagePicker name='profileImage' image={data?.user?.profile_image || null} />
              {/* <ThumbFile name="profileImage" image={data?.user?.profile_image?.url} imageId={data?.user?.profile_image?.id} /> */}
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='form-label mb-2 required'>Cover Image</label>
              {/* <ThumbFile name="coverImage" image={data?.user?.cover_image?.url} imageId={data?.user?.cover_image?.id} />
               */}
              <ImagePicker image={data?.user?.cover_image || null} name='coverImage' />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='form-group'>
            <label>Upload multiple images of rooms</label>
            <br />
            <FieldArray name='images'>
                {(fieldArrayProps: any) => {
                    const { form } = fieldArrayProps
                    const { setFieldValue, value } = form
                    return (
                        <input
                            type='file'
                            multiple
                            className='form-control'
                            onChange={(e) => uploadImage(e, setFieldValue, value)}
                        />
                    )
                }}
            </FieldArray>
        </div> */}

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          Verification Documents
        </label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-12 fv-row'>
              <label className='form-label mb-2 required'>Verification Documents</label>
              <MultipleImageUploader
                name='verificationInformation'
                files={data?.user?.files}
                invalidateQuery={['company']}
              />
            </div>
            {/* verification images requirement */}
            {!data?.user?.company_details.is_profile_completed && (
              <div>
                <h6 className='mt-5'>Verification document requirement</h6>
                <div className='form-text mb-4'>
                  Following requirement should be fulfilled inorder to be registered as an valid
                  company.
                </div>
                <div className='timeline-label'>
                  {/* begin::Item */}
                  <div className='timeline-item'>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-6'>1.</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      <i className='fa fa-genderless text-success fs-1'></i>
                    </div>
                    {/* end::Badge */}
                    {/* begin::Content */}
                    <div className='timeline-content d-flex'>
                      <span className='fw-bolder text-gray-800 ps-3'>Pan / Vat of the company</span>
                    </div>
                    {/* end::Content */}
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='timeline-item'>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-6'>2.</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      <i className='fa fa-genderless text-danger fs-1'></i>
                    </div>
                    {/* end::Badge */}
                    {/* begin::Desc */}
                    <div className='timeline-content fw-bolder text-gray-800 ps-3'>
                      Company registration document
                    </div>
                    {/* end::Desc */}
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierDetailsForm
