import React from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'

import {authenticationService} from '../../../services/api/authentication'

interface FormData {
  email: string
}
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 50 symbols')
    .required('Email is required'),
})

export const ForgotPassword = () => {
  const history = useHistory()
  const mutation = useMutation(authenticationService.forgotPassword)
  const onFormSubmit = (value: FormData) => {
    mutation.mutate(value.email, {
      onSuccess: () => {
        toast.success('Reset link has been sent to your email')
        history.push('/auth/login')
      },
      onError: (error: any) => {
        toast.error(`${error?.response?.data?.error?.message || "We are having problem right now, please try again later!!!"}`)
      },
    })
  }

  return (
    <>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
        {/* end::Link */}
      </div>
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {() => (
          <Form className='w-100 pb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <Field
              className='form-control form-control-lg form-control-solid'
              type='email'
              name='email'
            />
            <div className='text-danger'>
              <ErrorMessage name='email' />
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-8'>
              {' '}
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-10'
                disabled={mutation.isLoading}
              >
                {!mutation.isLoading && <span className='indicator-label'>Submit</span>}
                {mutation.isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder ms-4'
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
